/*****************************
  Banner
*****************************/
.banner-content{
	padding-top: 200px;
}

/*****************************
  Banner style 02
*****************************/
.destination-list-item {
	display: flex;
	position: relative;
	flex-wrap: wrap;
}
.destination-item {
	width: 25%;
	position: relative;
	&:before {
		content: "";
		background: rgba($white, 0.1);
		height: 100%;
		width: 2px;
		display: inline-block;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
	}
	.destination {
		z-index: 2;
		background: rgba($black, 0.1);
		transition: all 0.3s ease-in-out;
	}
	.destination-img {
		img {
			opacity: 0;
		}
	}
}
.destination-item.active {
	.destination {
		background: transparent;
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
		.destination-detail {
			bottom: 20px;
			.destination-content {
				opacity: 1;
			}
		}
	}
}
.destination-item-img {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-repeat: no-repeat;
	background-size: cover;
	transition: all 0.3s ease-in-out;
}
.destination-item-img.bg-1 {
	background-image: url(../images/banner/11.jpg);
}
.destination-item-img.bg-2 {
	background-image: url(../images/banner/10.jpg);
}
.destination-item-img.bg-3 {
	background-image: url(../images/banner/12.jpg);
}
.destination-item-img.bg-4 {
	background-image: url(../images/banner/13.jpg);
}


/* banner-new-style */
.banner-new-style {
	padding: 0 90px;
	margin-top: 100px;
	display: inline-block;
	.blog-post.post-style-12 {
    background: #f3f7ff;
    display: flex;
    align-items: center;
    height: 100%;
    .blog-details {
    	padding: 60px;
    	h4 {
    		margin-bottom: 30px;
    	}
    	.btn {
    		margin-top: 40px;
    	}
    }
	}
	.banner-new-img {
		height: 100%;
    	object-fit: cover;
		img {
			height: 100%;
			width:100%;
      		object-fit: cover;
		}
	}
}


@media (max-width:1430px) {
	.banner-new-style {
		padding: 0 0px;
		.banner-new-img{
			img{
				width:100%;
			}
		}
	}
}

@media (max-width:991px) {

	.banner-new-style {
		.blog-post.post-style-12 {
			.blog-details {
		    	padding: 60px 60px 60px 60px;
		    }
		}
    }
}

@media (max-width:767px) {

	.destination-list-item{
		.destination-item {
			width: 50%;
			.destination-img{
				img{
					height: 350px;
				}
			}

			.destination {
				.destination-detail {
					.destination-content{
						flex-wrap: wrap;
						margin-top: 8px;
						.upload-box{
							margin-bottom: 6px;
						}
					}
				}
			}
		}

	}
	.banner-new-style {
		padding: 0;
	  	margin-top: 90px;
	  	.blog-details {
	    	padding: 0px 30px 30px 30px;
	    }
		.banner-new-img{
			img{
				width:100%;
			}
		}
	}

}


@media (max-width:575px) {
	.destination-list-item{
		.destination-item {
			width: 100%;
			.destination-img{
				img{
					height: 200px;
				}
			}
		}
	}
	.banner-section{
		.destination-list-item{
			.destination {
				.destination-detail {
					padding: 0px 15px;
					.destination-info {
						span{
							margin-bottom: 6px;
						}
						h4{
							font-size: 16px;
							line-height: 24px;
						}
					}

					.destination-content{
						.upload-box{
							.share-box {
								.share-box-social{
									display: flex;
									li{
										a{
											padding: 10px 5px;
	    								text-align: center;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.banner-new-style {
		.blog-post.post-style-12 {
			.blog-details {
		    	padding: 30px 30px 30px 30px;
		    	h4{
		    		font-size: 26px;
    				line-height: 42px;
		    	}
		    }
		}
    }
}

@supports ((-webkit-backdrop-filter: blur(9px)) or (backdrop-filter: blur(9px))) {
  .destination-item:before,
  .destination-item.active .destination {
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
  }
}