/*

Template: News and Magazine HTML Template
Version: 1.0.0
Author: potenzaglobalsolutions
Design and Developed by: potenzaglobalsolutions.com

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template.

*/
/*==============================================
[  Table of contents  ]
================================================

 :: Typography
 :: Helper Classes
 :: Shortcodes
    - Accordion
    - Badge
    - Button
    - Category
    - Form
    - Owl carousel
    - Range Slider
    - Select
    - Sidebar
    - Tabs
    - Team
 :: Header
   - Header
   - Header Transparent
   - Header Style 02
   - Header Style 03
   - Header Style 04
   - Header Style 06
 :: Banner
   - Banner
 :: Layout
   - Section Title
   - follow style 01
   - follow style 02
   - follow style 03
   - follow style 04
   - follow style 05
 :: Blog
   - Blog Post
   - Post Style 01
   - Post Style 02
   - Post Style 03
   - Post Style 04
   - Post Style 05
   - Post Style 06
   - Post Style 07
   - Post Style 08
   - Post Style 09
   - Post style 10
   - Post style 11
   - Post style 12
   - Post style 13
 :: Shop
   - Product
   - Product Detail
    - Shop Sidebar
    - Checkout
    - Cart
 :: Not Found
 :: Footer

======================================
[ End table content ]
======================================*/
/*****************************
    Helper Classes
*****************************/
.space-ptb {
  padding: 50px 0; }

.space-pt {
  padding: 50px 0 0; }

.space-pb {
  padding: 0 0 50px; }

.space-lg-ptb {
  padding: 100px 0; }

.space-lg-pt {
  padding: 100px 0 0; }

.space-lg-pb {
  padding: 0 0 100px; }

.space-sm-ptb {
  padding: 30px 0; }

.space-sm-pt {
  padding: 30px 0 0; }

.space-sm-pb {
  padding: 0 0 30px; }

.mt-n9 {
  margin-top: -200px; }

/*bg-color*/
.bg-red {
  background: #dc3545; }

.bg-blue {
  background: #0d6efd !important; }

.bg-yellow {
  background: #ffc107 !important; }

.bg-pink {
  background: #d63384 !important; }

.bg-orange {
  background: #fd7e14 !important; }

.bg-purple {
  background: #6610f2 !important; }

.bg-skyblue {
  background: #0dcaf0 !important; }

.bg-light {
  background: #e4eaf7 !important; }

.bg-dark {
  background: #051830 !important; }

.bg-primary {
  background: #30c96a !important; }

.bg-green {
  background: #5d9711 !important; }

.text-red {
  color: #dc3545 !important; }

.text-blue {
  color: #0d6efd !important; }

.text-yellow {
  color: #ffc107 !important; }

.text-pink {
  color: #d63384 !important; }

.text-orange {
  color: #fd7e14 !important; }

.text-purple {
  color: #6610f2 !important; }

.text-skyblue {
  color: #0dcaf0 !important; }

.text-light {
  color: #e4eaf7; }

.text-dark {
  color: #051830; }

.text-primary {
  color: #30c96a !important; }

.text-green {
  color: #5d9711 !important; }

.border-radius {
  border-radius: 6px; }

.bg-overlay-black-10 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-10:before {
    background: rgba(5, 24, 48, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-20 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-20:before {
    background: rgba(5, 24, 48, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-30 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-30:before {
    background: rgba(5, 24, 48, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-40 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-40:before {
    background: rgba(5, 24, 48, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1; }

.bg-overlay-black-50 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-50:before {
    background: rgba(5, 24, 48, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-60 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-60:before {
    background: rgba(5, 24, 48, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-70 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-70:before {
    background: rgba(5, 24, 48, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-80 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-80:before {
    background: rgba(5, 24, 48, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-90 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-90:before {
    background: rgba(5, 24, 48, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%; }

/*form-group*/
.form-group {
  margin-bottom: 1rem; }

.font-xxxl {
  font-size: 40px;
  line-height: 40px; }

@media (min-width: 1200px) {
  .container {
    max-width: 1440px; } }

@media (max-width: 1200px) {
  .container {
    max-width: 100%; }
  .space-lg-ptb {
    padding: 80px 0; }
  .space-lg-pt {
    padding: 80px 0 0; }
  .space-lg-pb {
    padding: 0 0 80px; } }

@media (max-width: 991px) {
  .space-lg-ptb {
    padding: 60px 0; }
  .space-lg-pt {
    padding: 60px 0 0; }
  .space-lg-pb {
    padding: 0 0 60px; } }

@media (max-width: 767px) {
  .space-lg-ptb {
    padding: 50px 0; }
  .space-lg-pt {
    padding: 50px 0 0; }
  .space-lg-pb {
    padding: 0 0 50px; }
  .mt-n9 {
    margin-top: 0px; } }

@media (max-width: 575px) {
  .space-ptb {
    padding: 40px 0; }
  .space-pt {
    padding: 40px 0 0; }
  .space-pb {
    padding: 0 0 40px; } }

/*****************************
    Typography
*****************************/
body {
  font-family: "Red Hat Display", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  color: #5c667d;
  overflow-x: hidden; }

a {
  outline: medium none !important;
  color: #30c96a;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important; }
  a:focus {
    color: #30c96a;
    text-decoration: none !important; }
  a:hover {
    color: #30c96a;
    text-decoration: none !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Zilla Slab", serif;
  font-weight: normal;
  color: #023a51;
  margin-top: 0px;
  text-transform: capitalize; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: inherit; }

h1 {
  font-size: 62px;
  font-weight: 700; }

h2 {
  font-size: 36px;
  font-weight: 700; }

h3 {
  font-size: 32px;
  font-weight: 700; }

h4 {
  font-size: 28px; }

h5 {
  font-size: 24px; }

h6 {
  font-size: 22px; }

small {
  font-size: 14px;
  font-weight: normal; }

p {
  font-weight: normal;
  line-height: 24px; }

.container {
  max-width: 1430px; }

ul {
  padding: 0px;
  margin: 0px;
  list-style: none; }

input.form-control {
  height: 50px; }

.blockquote {
  font-size: 16px;
  font-style: italic;
  display: block;
  padding: 40px;
  background: #e4eaf7;
  border: 1px solid #e4eaf7;
  position: relative;
  border-radius: 6px; }
  .blockquote i {
    z-index: 0;
    font-size: 30px;
    line-height: 30px; }

.form-control:focus {
  box-shadow: none;
  border-color: #30c96a; }

input {
  outline: medium none !important; }

@media (max-width: 991px) {
  h1 {
    font-size: 52px;
    font-weight: 700; }
  h2 {
    font-size: 32px;
    font-weight: 700; }
  h3 {
    font-size: 28px;
    font-weight: 700; }
  h4 {
    font-size: 28px; }
  h5 {
    font-size: 22px; }
  h6 {
    font-size: 20px; } }

@media (max-width: 767px) {
  h1 {
    font-size: 42px;
    font-weight: 700; }
  h2 {
    font-size: 28px;
    font-weight: 700; }
  h3 {
    font-size: 26px;
    font-weight: 700; }
  h4 {
    font-size: 24px; }
  h5 {
    font-size: 20px; }
  h6 {
    font-size: 18px; } }

/*****************************
    layout css
*****************************/
/*Section Title*/
.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d1d8e6;
  padding-bottom: 18px;
  margin-bottom: 30px; }
  .section-title h2 {
    font-size: 28px; }
    .section-title h2 i {
      font-size: 24px;
      color: #30c96a;
      margin-right: 15px; }
  .section-title .title {
    display: flex;
    align-items: center; }
    .section-title .title i {
      margin-right: 15px; }

hr {
  border-top: 1px solid #e4eaf7;
  opacity: 1; }

/*Countrys*/
.countrys {
  background: #e4eaf7;
  padding: 15px;
  border-radius: 6px; }
  .countrys .country-01 h4 {
    font-family: "Red Hat Display", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px; }
  .countrys .country-02 h4 {
    font-family: "Red Hat Display", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px; }
  .countrys .country-date p {
    font-size: 12px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    margin-bottom: 0; }
  .countrys .country-flag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    border-top: 1px solid #d1d8e6; }
    .countrys .country-flag img {
      width: 50px; }

.newsletter {
  background: #e4eaf7;
  padding: 30px;
  text-align: center;
  border-radius: 6px; }
  .newsletter i {
    font-size: 60px;
    margin-bottom: 25px;
    color: #5c667d; }
  .newsletter p {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 24px; }
  .newsletter .form-control {
    padding: 14px 20px;
    height: 50px;
    font-size: 14px;
    border-color: #ffffff;
    transition: all 0.3s ease-in-out;
    color: #051830;
    border-radius: 6px; }
    .newsletter .form-control:focus {
      box-shadow: none;
      border-color: #30c96a; }
  .newsletter .newsletter-box input {
    margin-bottom: 10px; }
  .newsletter .newsletter-box button {
    display: block;
    width: 100%; }

/*follow style 01*/
.follow-style-01 .social-box {
  padding: 12px 20px;
  border-radius: 6px;
  border: 1px solid #e4eaf7; }
  .follow-style-01 .social-box .social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; }
    .follow-style-01 .social-box .social a.fans-icon {
      color: #4876a3; }
    .follow-style-01 .social-box .social a i {
      font-size: 24px; }
    .follow-style-01 .social-box .social span {
      font-size: 16px;
      color: #ffffff; }
    .follow-style-01 .social-box .social:before {
      content: '';
      top: 45%;
      position: absolute;
      border: 1px dashed #d1d8e6;
      left: 30px;
      width: calc(100% - 70px); }
  .follow-style-01 .social-box .follower-btn a {
    text-align: center;
    border-radius: 6px;
    color: #ffffff;
    display: block;
    padding: 2px 25px;
    margin-top: 5px; }
  .follow-style-01 .social-box .fans a {
    background: #4876a3; }

.follow-style-01 .facebook-fans .social span {
  color: #4876a3; }

.follow-style-01 .twitter-follower .social .twitter-icon i {
  color: #20b5e6; }

.follow-style-01 .twitter-follower .social span {
  color: #20b5e6; }

.follow-style-01 .twitter-follower .follower a {
  background: #37bde9; }

.follow-style-01 .you-tube .social .tube-icon i {
  color: #dd4237; }

.follow-style-01 .you-tube .social span {
  color: #dd4237; }

.follow-style-01 .you-tube .subscriber a {
  background: #dd4237; }

.follow-style-01 .instagram-Follower .social .instagram-icon i {
  color: #d25b7a; }

.follow-style-01 .instagram-Follower .social span {
  color: #d25b7a; }

.follow-style-01 .instagram-Follower .instagrams a {
  background: #d25b7a; }

/*Follow Style 02*/
.follow-style-02 .facebook-fans {
  background: #336699; }

.follow-style-02 .social-box {
  padding: 12px 20px;
  border-radius: 6px; }
  .follow-style-02 .social-box .social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; }
    .follow-style-02 .social-box .social a i {
      font-size: 24px;
      color: #fff; }
    .follow-style-02 .social-box .social span {
      font-size: 16px;
      color: #ffffff; }
    .follow-style-02 .social-box .social:before {
      content: '';
      top: 45%;
      position: absolute;
      border: 1px dashed #d1d8e6;
      left: 30px;
      width: calc(100% - 70px); }
  .follow-style-02 .social-box .follower-btn a {
    text-align: center;
    border-radius: 6px;
    color: #ffffff;
    display: block;
    padding: 2px 25px;
    margin-top: 5px; }
  .follow-style-02 .social-box .fans a {
    background: #4876a3; }

.follow-style-02 .twitter-follower {
  background: #20b5e6; }
  .follow-style-02 .twitter-follower .follower a {
    background: #37bde9; }

.follow-style-02 .you-tube {
  background: #d92c20; }
  .follow-style-02 .you-tube .subscriber a {
    background: #dd4237; }

.follow-style-02 .instagram-Follower {
  background: #cd486b; }
  .follow-style-02 .instagram-Follower .instagrams a {
    background: #d25b7a; }

/*Follow Style 03*/
.follow-style-03 .social ul {
  display: block; }
  .follow-style-03 .social ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #336699;
    padding: 11px 20px;
    border-radius: 6px;
    margin-right: 0; }
    .follow-style-03 .social ul li .facebook-icon a {
      font-size: 16px;
      color: #336699;
      font-weight: 600; }
      .follow-style-03 .social ul li .facebook-icon a i {
        padding-right: 10px; }
    .follow-style-03 .social ul li .facebook-content {
      align-items: center;
      display: flex; }
      .follow-style-03 .social ul li .facebook-content span {
        font-size: 16px;
        color: #336699;
        font-weight: 600;
        margin-right: 10px; }
      .follow-style-03 .social ul li .facebook-content a {
        background: #336699;
        padding: 1px 20px;
        font-size: 12px;
        border-radius: 100px;
        color: #fff; }
  .follow-style-03 .social ul li.twitter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #20b5e6;
    padding: 11px 20px;
    border-radius: 6px;
    margin-top: 10px; }
    .follow-style-03 .social ul li.twitter .twitter-icon a {
      font-size: 16px;
      color: #20b5e6;
      font-weight: 600; }
      .follow-style-03 .social ul li.twitter .twitter-icon a i {
        padding-right: 10px; }
    .follow-style-03 .social ul li.twitter .twitter-content {
      align-items: center;
      display: flex; }
      .follow-style-03 .social ul li.twitter .twitter-content span {
        font-size: 16px;
        color: #20b5e6;
        font-weight: 600;
        margin-right: 10px; }
      .follow-style-03 .social ul li.twitter .twitter-content a {
        background: #20b5e6;
        padding: 1px 20px;
        font-size: 12px;
        border-radius: 100px;
        color: #fff; }

.follow-style-03 .social li.youtube {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d92c20;
  padding: 11px 20px;
  border-radius: 6px;
  margin-top: 10px; }
  .follow-style-03 .social li.youtube .youtube-icon a {
    font-size: 16px;
    color: #d92c20;
    font-weight: 600; }
    .follow-style-03 .social li.youtube .youtube-icon a i {
      padding-right: 10px; }
  .follow-style-03 .social li.youtube .youtube-content {
    align-items: center;
    display: flex; }
    .follow-style-03 .social li.youtube .youtube-content span {
      font-size: 16px;
      color: #d92c20;
      font-weight: 600;
      margin-right: 10px; }
    .follow-style-03 .social li.youtube .youtube-content a {
      background: #d92c20;
      padding: 1px 20px;
      font-size: 12px;
      border-radius: 100px;
      color: #fff; }

.follow-style-03 .social li.instagrams {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #cd486b;
  padding: 11px 20px;
  border-radius: 6px;
  margin-top: 10px; }
  .follow-style-03 .social li.instagrams .instagram-icon a {
    font-size: 16px;
    color: #cd486b;
    font-weight: 600; }
    .follow-style-03 .social li.instagrams .instagram-icon a i {
      padding-right: 10px; }
  .follow-style-03 .social li.instagrams .instagram-content {
    align-items: center;
    display: flex; }
    .follow-style-03 .social li.instagrams .instagram-content span {
      font-size: 16px;
      color: #cd486b;
      font-weight: 600;
      margin-right: 10px; }
    .follow-style-03 .social li.instagrams .instagram-content a {
      background: #cd486b;
      padding: 1px 20px;
      font-size: 12px;
      border-radius: 100px;
      color: #fff; }

/*Follow Style 04*/
.follow-style-04 .social ul {
  display: block; }
  .follow-style-04 .social ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 20px;
    border-radius: 6px;
    background: #336699;
    margin-right: 0px;
    margin-top: 10px; }
    .follow-style-04 .social ul li .social-icon a {
      font-size: 16px;
      color: #ffffff;
      font-weight: 500; }
      .follow-style-04 .social ul li .social-icon a i {
        padding-right: 10px; }
    .follow-style-04 .social ul li .social-content {
      align-items: center;
      display: flex; }
      .follow-style-04 .social ul li .social-content span {
        font-size: 16px;
        color: #ffffff;
        font-weight: 500;
        margin-right: 10px; }
      .follow-style-04 .social ul li .social-content a {
        padding: 1px 20px;
        font-size: 12px;
        border-radius: 100px;
        color: #ffffff; }
  .follow-style-04 .social ul li.facebook {
    background: #336699; }
    .follow-style-04 .social ul li.facebook .social-content a {
      background: #517da8; }
  .follow-style-04 .social ul li.twitter {
    background: #20b5e6; }
    .follow-style-04 .social ul li.twitter .social-content a {
      background: #41c0ea; }
  .follow-style-04 .social ul li.youtube {
    background: #d92c20; }
    .follow-style-04 .social ul li.youtube .social-content a {
      background: #df4b41; }
  .follow-style-04 .social ul li.instagrams {
    background: #cd486b; }
    .follow-style-04 .social ul li.instagrams .social-content a {
      background: #d46381; }

/*follow style 05*/
.follow-style-05 .social ul {
  display: flex;
  flex-wrap: wrap; }
  .follow-style-05 .social ul li {
    text-align: center;
    margin-right: 30px; }
    .follow-style-05 .social ul li a {
      font-size: 30px;
      color: #ffffff;
      background: #336699;
      border-radius: 6px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      line-height: 60px; }
      .follow-style-05 .social ul li a:hover {
        color: #fff; }
    .follow-style-05 .social ul li .user-like {
      padding-top: 10px; }
      .follow-style-05 .social ul li .user-like span {
        display: block;
        font-size: 16px; }
      .follow-style-05 .social ul li .user-like span.user {
        font-size: 12px;
        padding-top: 2px; }
  .follow-style-05 .social ul li.twitter a {
    background: #20b5e6; }
  .follow-style-05 .social ul li.youtube a {
    background: #d92c20; }
  .follow-style-05 .social ul li.instagrams a {
    background: #cd486b; }

/*social-rounded*/
.social-icon.social-rounded ul li a {
  color: #ffffff;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  border: 1px solid #ffffff;
  border-radius: 100%;
  margin-right: 30px;
  font-size: 18px; }
  .social-icon.social-rounded ul li a:hover {
    background: #30c96a;
    border: 1px solid #30c96a; }

/*Destination*/
.destination {
  position: relative;
  overflow: hidden; }
  .destination .destination-img {
    position: relative; }
    .destination .destination-img img {
      overflow: hidden;
      transition: transform 1s cubic-bezier(0.25, 0.04, 0, 0.93);
      transform: translate3d(0, 0, 0) scale(1.06); }
    .destination .destination-img:before {
      z-index: 1;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.6) 100%);
      top: auto;
      height: 40%;
      transition: all .35s; }
  .destination .destination-detail {
    position: absolute;
    bottom: -20px;
    left: 0;
    right: inherit;
    padding: 0 30px;
    width: 100%;
    transition: all 0.3s ease-in-out;
    z-index: 9; }
    .destination .destination-detail .destination-info span {
      margin-bottom: 15px; }
    .destination .destination-detail .destination-info h4 {
      font-weight: 700;
      margin-bottom: 0;
      color: #ffffff;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical; }
      .destination .destination-detail .destination-info h4 a {
        background-image: linear-gradient(transparent calc(100% - 1px), currentColor 1px);
        background-image: -ms-linear-gradient(transparent 95%, currentColor 1px);
        background-repeat: no-repeat;
        background-size: 0 100%;
        background-position: 0 -1px;
        transition: all 0.6s cubic-bezier(0.45, 0, 0.14, 1.03);
        overflow: hidden; }
    .destination .destination-detail .destination-content {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      opacity: 0; }
      .destination .destination-detail .destination-content .upload-box {
        display: flex;
        align-items: center; }
        .destination .destination-detail .destination-content .upload-box a {
          width: 30px;
          height: 30px;
          flex: 0 0 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #80848b;
          border-radius: 100%;
          color: #fff; }
          .destination .destination-detail .destination-content .upload-box a i {
            font-size: 12px; }
        .destination .destination-detail .destination-content .upload-box span.dots {
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background: #ffffff;
          position: relative;
          display: flex;
          margin-left: 30px; }
          .destination .destination-detail .destination-content .upload-box span.dots:before {
            position: absolute;
            content: "";
            left: -8px;
            top: 0;
            width: 5px;
            height: 5px;
            background: #ffffff;
            border-radius: 5px; }
          .destination .destination-detail .destination-content .upload-box span.dots:after {
            position: absolute;
            content: "";
            right: -8px;
            top: 0;
            width: 4px;
            height: 4px;
            background: #ffffff;
            border-radius: 5px; }
        .destination .destination-detail .destination-content .upload-box .share-box {
          position: relative;
          z-index: 99; }
          .destination .destination-detail .destination-content .upload-box .share-box > a {
            height: auto;
            width: auto;
            background-color: inherit;
            display: inline-block;
            margin-left: 25px; }
          .destination .destination-detail .destination-content .upload-box .share-box .share-box-social {
            position: absolute;
            border-radius: 6px;
            background-color: #ffffff;
            padding: 5px;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            left: 0;
            bottom: 100%;
            transition: all 0.3s ease-in-out;
            min-width: 160px; }
            .destination .destination-detail .destination-content .upload-box .share-box .share-box-social li {
              transition: all 0.5s ease-in-out; }
              .destination .destination-detail .destination-content .upload-box .share-box .share-box-social li a {
                background-color: transparent;
                color: #5c667d;
                padding: 10px 15px;
                font-size: 14px;
                width: auto;
                height: auto;
                display: block;
                border-radius: 3px; }
                .destination .destination-detail .destination-content .upload-box .share-box .share-box-social li a i {
                  font-size: 14px;
                  padding-right: 10px; }
              .destination .destination-detail .destination-content .upload-box .share-box .share-box-social li:hover {
                color: #30c96a;
                background-color: #e4eaf7;
                visibility: visible; }
          .destination .destination-detail .destination-content .upload-box .share-box:hover .share-box-social {
            opacity: 1;
            visibility: visible;
            margin-bottom: 6px; }
      .destination .destination-detail .destination-content .blog-post-meta {
        display: flex;
        align-items: center; }
        .destination .destination-detail .destination-content .blog-post-meta .blog-post-comment {
          margin-right: 25px; }
          .destination .destination-detail .destination-content .blog-post-meta .blog-post-comment a {
            color: #ffffff; }
            .destination .destination-detail .destination-content .blog-post-meta .blog-post-comment a i {
              padding-right: 10px; }
        .destination .destination-detail .destination-content .blog-post-meta .blog-post-share a {
          color: #ffffff; }
          .destination .destination-detail .destination-content .blog-post-meta .blog-post-share a i {
            padding-right: 10px; }
  .destination:hover .destination-img img {
    transform: translate3d(3%, 0, 0) scale(1.06); }
  .destination:hover .destination-detail {
    bottom: 20px; }
    .destination:hover .destination-detail .destination-info h4 a {
      background-size: 100% 100%;
      color: inherit; }
    .destination:hover .destination-detail .destination-content {
      opacity: 1; }

/*instagram*/
.instagram {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  text-align: center;
  background: #30c96a;
  padding: 30px; }
  .instagram .instagram-icon i {
    font-size: 65px;
    color: #ffffff; }
  .instagram .instagram-icon h6 {
    font-size: 20px;
    padding: 20px 0;
    margin-bottom: 0;
    font-weight: 700;
    color: #ffffff; }
  .instagram .instagram-icon a {
    color: #fff;
    font-size: 16px;
    font-weight: 700; }

/*Search*/
.search ul li a {
  color: #000000;
  display: flex; }
  .search ul li a:hover {
    color: #30c96a; }

#search {
  position: absolute;
  top: 55px;
  left: 0px;
  width: 100%;
  background-color: #f5f5f5;
  opacity: 0;
  visibility: hidden;
  transform: scale3d(0.8, 0.8, 0.8);
  transition: 0.3s;
  z-index: 999;
  padding: 27px 0px; }
  #search .search-popup {
    display: flex; }

#search.search-style-02 {
  top: 0px; }

#search.open {
  opacity: 1;
  visibility: visible;
  transform: scale3d(1, 1, 1); }

#search input[type="search"] {
  width: 100%;
  color: #5c667d;
  background: #ffffff;
  border: 1px solid #e4eaf7;
  font-size: 16px;
  height: 50px;
  font-weight: 300;
  border-radius: 6px 0px 0px 6px;
  margin: 0px auto;
  margin-top: 0px;
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  text-transform: capitalize; }

#search .btn {
  border-radius: 0px 6px 6px 0px; }

#search .close {
  position: absolute;
  z-index: 9;
  top: 50%;
  right: 15px;
  background-color: #041f42;
  color: #ffffff;
  opacity: 1;
  padding: 11px 19px;
  font-size: 18px;
  border-radius: 6px;
  border: transparent;
  transition: all 0.3s ease-in-out;
  transform: translate(0%, -50%); }
  #search .close:hover {
    background-color: #30c96a; }
  #search .close i {
    position: relative;
    z-index: -1; }

/*breaking-news*/
.breaking-news {
  background-color: #041f42;
  padding: 7px;
  margin-bottom: 30px; }
  .breaking-news .news-btn {
    background-color: #30c96a;
    display: inline-block;
    padding: 6px 15px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 4px;
    color: #ffffff; }
  .breaking-news .owl-carousel.arrow-styel-03 {
    padding-right: 90px; }
    .breaking-news .owl-carousel.arrow-styel-03 .owl-nav {
      opacity: 1; }
      .breaking-news .owl-carousel.arrow-styel-03 .owl-nav button {
        height: 24px;
        width: 24px;
        font-size: 12px;
        color: #30c96a; }
      .breaking-news .owl-carousel.arrow-styel-03 .owl-nav button.owl-prev {
        left: inherit;
        right: 30px;
        top: 14px; }
        .breaking-news .owl-carousel.arrow-styel-03 .owl-nav button.owl-prev .fa-arrow-left-long:before {
          content: "\f053" !important;
          font-family: "Font Awesome 6 Free";
          font-weight: 900; }
      .breaking-news .owl-carousel.arrow-styel-03 .owl-nav button.owl-next {
        top: 14px; }
        .breaking-news .owl-carousel.arrow-styel-03 .owl-nav button.owl-next .fa-arrow-right-long:before {
          content: "\f054" !important;
          font-family: "Font Awesome 6 Free";
          font-weight: 900; }
    .breaking-news .owl-carousel.arrow-styel-03:hover .owl-nav button.owl-next {
      right: 0; }
    .breaking-news .owl-carousel.arrow-styel-03 .news-post {
      padding-right: 15px; }

/*viedo*/
.video-box {
  margin-right: 35px;
  position: relative; }
  .video-box .video-image {
    position: relative; }
    .video-box .video-image img {
      border-radius: 10px; }
    .video-box .video-image .video-btn {
      position: absolute;
      top: 50px;
      left: inherit;
      transform: translate(-50%, -50%);
      right: 0%; }
      .video-box .video-image .video-btn i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background: #fff;
        border-radius: 5px;
        -webkit-transition: all 200ms;
        transition: all 200ms;
        font-size: 22px;
        color: #30c96a; }
      .video-box .video-image .video-btn:before {
        content: "";
        position: absolute;
        z-index: 0;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        width: 50px;
        height: 50px;
        border: 5px solid #ffffff;
        border-radius: 5px;
        animation: pulse-border 1.5s ease-out infinite; }
  .video-box:before {
    z-index: 1;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.6) 100%);
    top: auto;
    height: 40%;
    -webkit-transition: all .35s;
    transition: all .35s; }

@keyframes pulse-border {
  0% {
    transform: translateX(0%) translateY(0%) translateZ(0) scale(1);
    opacity: 1; }
  100% {
    transform: translateX(0%) translateY(0%) translateZ(0) scale(1.5);
    opacity: 0; } }

/*add-banner*/
.add-banner {
  margin-top: 30px; }
  .add-banner a {
    position: relative;
    overflow: hidden;
    display: block; }
    .add-banner a:before {
      position: absolute;
      top: 0;
      left: -75%;
      z-index: 2;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
      -webkit-transform: skewX(-25deg);
      transform: skewX(-25deg); }
    .add-banner a:hover:before {
      -webkit-animation: shine 2.0s;
      animation: shine 2.0s; }
    .add-banner a img {
      border-radius: 6px; }

.add-widget .add-banner {
  margin-top: 0px; }
  .add-widget .add-banner a:hover:before {
    -webkit-animation: shine 1.0s;
    animation: shine 1.0s; }

/*inner-header*/
.inner-header {
  padding: 15px 0;
  background: #e1ebff; }
  .inner-header .breadcrumb {
    margin-bottom: 0; }
    .inner-header .breadcrumb li:before {
      content: none; }

/*author*/
.author-detail {
  display: flex;
  margin-bottom: 70px;
  padding: 25px;
  border: 1px solid #e4eaf7;
  border-radius: 6px; }
  .author-detail .author-image {
    min-width: 130px;
    width: 130px; }
    .author-detail .author-image img {
      border-radius: 6px; }
  .author-detail .author-info {
    margin-left: 30px; }
    .author-detail .author-info .author-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap; }
      .author-detail .author-info .author-box h3 {
        margin-bottom: 0;
        font-size: 22px; }
      .author-detail .author-info .author-box .author-social .social-icons {
        display: flex; }
        .author-detail .author-info .author-box .author-social .social-icons li {
          margin-right: 30px; }
          .author-detail .author-info .author-box .author-social .social-icons li a.social-icon.facebook {
            font-size: 22px;
            color: #336699; }
          .author-detail .author-info .author-box .author-social .social-icons li a.social-icon.twitter {
            font-size: 22px;
            color: #20b5e6; }
          .author-detail .author-info .author-box .author-social .social-icons li a.social-icon.linkedin {
            font-size: 22px;
            color: #336699; }
          .author-detail .author-info .author-box .author-social .social-icons li a.social-icon.instagram {
            font-size: 22px;
            color: #d25b7a;
            display: inherit;
            padding: 0;
            background: inherit; }
    .author-detail .author-info p {
      padding-top: 10px; }
    .author-detail .author-info .comment {
      margin-bottom: 25px; }
      .author-detail .author-info .comment ul {
        display: flex;
        align-items: center; }
        .author-detail .author-info .comment ul li {
          margin-right: 5px; }
          .author-detail .author-info .comment ul li a {
            font-size: 18px;
            color: #5c667d; }

/* blog-post post-style-10 */
.blog-post.post-style-10 .blog-details .blog-post-details span.text-purple:before {
  background: #6610f2; }

.blog-post.post-style-10 .blog-details .blog-post-details span.text-red:before {
  background: #dc3545; }

.blog-post.post-style-10 .blog-details .blog-post-details span.text-primary:before {
  background: #30c96a; }

.blog-post.post-style-10 .blog-details .blog-post-details span.text-skyblue:before {
  background: #0dcaf0; }

.blog-post.post-style-10 .blog-details .blog-post-details span.text-orange:before {
  background: #fd7e14; }

/*Home 05*/
.news-politics-post .sidebar-img a {
  position: relative;
  overflow: hidden;
  display: block; }
  .news-politics-post .sidebar-img a img {
    border-radius: 6px; }
  .news-politics-post .sidebar-img a:before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
    transform: skewX(-25deg); }
  .news-politics-post .sidebar-img a:hover:before {
    animation: shine 1.0s; }

/* Home 06*/
.news-medical-posts ul.current-list li {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e4eaf7;
  font-family: zilla;
  font-weight: 600;
  color: #5c667d; }
  .news-medical-posts ul.current-list li span {
    padding-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .news-medical-posts ul.current-list li span:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      border: 1px solid #051830;
      width: 6px;
      height: 6px;
      border-radius: 50px; }
  .news-medical-posts ul.current-list li:last-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: none; }

ul.current-news li {
  position: relative;
  font-family: zilla;
  font-weight: 600;
  color: #5c667d;
  font-size: 18px; }
  ul.current-news li span.border-end:after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0;
    width: 1px;
    height: 60px; }

/*404*/
.content-404 h2 {
  font-size: 60px;
  color: #023a51;
  padding-top: 50px; }

.content-404 p {
  font-size: 16px;
  color: #5c667d;
  margin-bottom: 40px; }

/*weather*/
.weather-01 {
  border: 1px solid #e4eaf7;
  border-radius: 6px; }
  .weather-01 .weather-top {
    padding: 40px;
    border-radius: 6px;
    background: #041f42; }
    .weather-01 .weather-top .weather-location h6 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: #ffffff; }
    .weather-01 .weather-top .weather-location .weather-cloud img {
      height: 40px; }
    .weather-01 .weather-top .weather-tempBlock {
      margin-top: 15px; }
      .weather-01 .weather-top .weather-tempBlock .weather-currentTemp {
        font-size: 48px;
        line-height: 48px;
        font-weight: 900;
        color: #ffffff; }
  .weather-01 .weather-bottom {
    padding: 25px 0px;
    overflow: auto;
    height: 300px;
    padding-right: 2px; }
    .weather-01 .weather-bottom::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 100px; }
    .weather-01 .weather-bottom::-webkit-scrollbar {
      width: 3px;
      background-color: #000;
      border-radius: 100px; }
    .weather-01 .weather-bottom::-webkit-scrollbar-thumb {
      background-color: #e4eaf7; }
    .weather-01 .weather-bottom .weather-daily-component {
      align-items: center;
      margin-bottom: 20px; }
      .weather-01 .weather-bottom .weather-daily-component .weather-daily .weather-date {
        font-size: 16px; }
      .weather-01 .weather-bottom .weather-daily-component .weather-daily .weather-day {
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 500; }
      .weather-01 .weather-bottom .weather-daily-component:last-child {
        margin-bottom: 0px; }
      .weather-01 .weather-bottom .weather-daily-component .weather-daily-max, .weather-01 .weather-bottom .weather-daily-component .weather-daily-min {
        font-weight: 500; }

/* Sticky column */
.is-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 130px; }

@-webkit-keyframes "shine" {
  100% {
    left: 125%; } }

@keyframes "shine" {
  100% {
    left: 125%; } }

.lion-section .blog-post.post-style-13 {
  padding-top: 15px;
  margin-top: 15px; }

.lion-section .blog-post.post-style-03 .blog-post-details h6.blog-title {
  font-size: 20px; }

.lion-section .lion-content {
  border-top: 1px solid #e4eaf7;
  padding-top: 25px;
  margin-top: 20px; }
  .lion-section .lion-content .blog-title {
    font-size: 18px;
    font-weight: 700; }
  .lion-section .lion-content .blog-title.right-blog {
    border-left: 1px solid #e4eaf7;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 700; }

/*contact us*/
.contact-info {
  padding-right: 50px; }
  .contact-info .map-info .map {
    margin-bottom: 15px; }
  .contact-info .map-info h4 {
    font-weight: bold; }

.form-info {
  padding: 40px; }

.contact-chat {
  padding: 50px;
  background-color: #041f42;
  border-radius: 6px; }
  .contact-chat .btn {
    margin-top: 40px; }
    .contact-chat .btn:hover {
      background-color: #ffffff;
      color: #30c96a; }

.contact-online {
  padding: 50px;
  background-color: #30c96a;
  border-radius: 6px; }
  .contact-online h4 {
    margin-top: 40px;
    margin-bottom: 0; }

.contact-us {
  padding: 50px;
  background-color: #e4eaf7;
  border-radius: 6px; }
  .contact-us h4 {
    margin-top: 40px;
    margin-bottom: 0; }

/*live Score*/
.live-score {
  display: flex;
  align-items: center;
  background-color: #041f42;
  border-radius: 6px; }
  .live-score .live-score-left {
    display: flex;
    align-items: center;
    flex: 0 0 165px;
    border-radius: 6px 0px 0px 6px;
    background-color: #30c96a;
    padding: 20px; }
    .live-score .live-score-left .title {
      font-size: 20px;
      line-height: 28px;
      font-weight: bold;
      text-transform: uppercase;
      color: #ffffff;
      position: relative; }
      .live-score .live-score-left .title .live-btn {
        background-color: #dc3545;
        position: absolute;
        top: 8px;
        right: 0px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        display: inline-block; }
        .live-score .live-score-left .title .live-btn:before, .live-score .live-score-left .title .live-btn:after {
          content: "";
          width: 20px;
          height: 20px;
          position: absolute;
          opacity: 1;
          left: -5px;
          top: 50%;
          transform: translateY(-50%); }
        .live-score .live-score-left .title .live-btn:before {
          background: #dc3545;
          border-radius: 100%;
          animation: customOne 1.5s infinite; }
        .live-score .live-score-left .title .live-btn:after {
          border: 5px solid #dc3545;
          border-radius: 100%;
          animation: customOne 2s infinite; }
    .live-score .live-score-left .sport-icon {
      margin-left: 20px; }
  .live-score .live-score-slider {
    width: calc(100% - 165px); }
    .live-score .live-score-slider .owl-carousel .owl-nav.disabled {
      opacity: 1;
      display: block; }
      .live-score .live-score-slider .owl-carousel .owl-nav.disabled button {
        height: 96px;
        color: #fff;
        background-color: #00193a !important;
        top: 50%;
        border-radius: 0; }
    .live-score .live-score-slider .owl-carousel:hover .owl-nav button.owl-prev {
      left: 0; }
    .live-score .live-score-slider .owl-carousel:hover .owl-nav button.owl-next {
      right: 0; }
    .live-score .live-score-slider .sport-tournament .tournament-details {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0 auto;
      padding-bottom: 5px;
      margin-bottom: 5px; }
      .live-score .live-score-slider .sport-tournament .tournament-details:before {
        content: "";
        position: absolute;
        bottom: 0px;
        left: inherit;
        width: 90%;
        height: 1px;
        border-bottom: 1px solid #1e3655; }
      .live-score .live-score-slider .sport-tournament .tournament-details span {
        font-size: 14px;
        color: #ffffff;
        margin-right: 15px; }
        .live-score .live-score-slider .sport-tournament .tournament-details span:last-child {
          margin-right: 0px; }
    .live-score .live-score-slider .sport-tournament .tournament-score {
      display: flex;
      align-items: center;
      justify-content: center; }
      .live-score .live-score-slider .sport-tournament .tournament-score .team-01 {
        margin-right: 40px; }
      .live-score .live-score-slider .sport-tournament .tournament-score .team-02 {
        margin-left: 40px; }
      .live-score .live-score-slider .sport-tournament .tournament-score .team {
        display: flex;
        align-items: center; }
        .live-score .live-score-slider .sport-tournament .tournament-score .team .team-name-flag {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #ffffff;
          margin-right: 15px; }
          .live-score .live-score-slider .sport-tournament .tournament-score .team .team-name-flag img {
            flex: 0 0 36px;
            margin-right: 10px; }
          .live-score .live-score-slider .sport-tournament .tournament-score .team .team-name-flag .team-name {
            width: 50px; }
        .live-score .live-score-slider .sport-tournament .tournament-score .team .team-score {
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
          margin-right: 15px; }
        .live-score .live-score-slider .sport-tournament .tournament-score .team .team-overs {
          font-size: 16px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.5); }
      .live-score .live-score-slider .sport-tournament .tournament-score .team-vs {
        font-size: 16px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.5);
        text-transform: uppercase; }
    .live-score .live-score-slider .sport-tournament hr {
      margin: 10px 0px; }

@keyframes customOne {
  0% {
    -webkit-transform: translateY(-50%) scale(0);
    -moz-transform: translateY(-50%) scale(0);
    transform: translateY(-50%) scale(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(-50%) scale(1.3);
    -moz-transform: translateY(-50%) scale(1.3);
    transform: translateY(-50%) scale(1.3);
    opacity: 0; } }

/*coin-table*/
.table.coin-table {
  border-radius: 6px; }
  .table.coin-table thead {
    background-color: #041f42;
    border-radius: 6px;
    border-bottom: none; }
    .table.coin-table thead tr th {
      color: #ffffff;
      font-size: 12px;
      font-family: "Zilla Slab", serif;
      font-weight: 600;
      padding: 6px 6px; }
      .table.coin-table thead tr th:first-child {
        border-radius: 6px 0px 0px 6px; }
      .table.coin-table thead tr th:last-child {
        border-radius: 0px 6px 6px 0px; }
  .table.coin-table tbody tr td:first-child {
    padding-left: 0px; }
  .table.coin-table tbody tr:last-child {
    border: transparent; }
  .table.coin-table .coin {
    display: flex;
    width: 105px; }
    .table.coin-table .coin .coin-icon {
      flex: 0 0 24px;
      margin-right: 8px; }
    .table.coin-table .coin .coin-name {
      font-size: 12px;
      font-family: "Zilla Slab", serif;
      font-weight: 600;
      color: #5c667d; }
      .table.coin-table .coin .coin-name span {
        display: block; }
  .table.coin-table .coin-price {
    font-size: 14px;
    font-family: "Zilla Slab", serif;
    font-weight: 600;
    color: #5c667d; }
  .table.coin-table .coin-price-change .coin-change-price {
    font-size: 14px;
    font-family: "Zilla Slab", serif;
    font-weight: normal;
    color: #5c667d;
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
  .table.coin-table .coin-price-change .coin-price-percentage {
    font-size: 14px;
    font-family: "Zilla Slab", serif;
    font-weight: normal;
    color: #ffffff;
    padding: 0px 10px;
    margin-top: 0px;
    width: 100%;
    display: inline-block;
    border-radius: 50px;
    text-align: center; }
  .table.coin-table .coin-price-change.price-up .coin-change-price i {
    color: #25ac75; }
  .table.coin-table .coin-price-change.price-up .coin-price-percentage {
    background-color: #25ac75; }
  .table.coin-table .coin-price-change.price-down .coin-change-price i {
    color: #e93f33; }
  .table.coin-table .coin-price-change.price-down .coin-price-percentage {
    background-color: #e93f33; }

/*News Popup*/
.news-popup.modal {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px; }
  .news-popup.modal .modal-content {
    border: none; }
    .news-popup.modal .modal-content .btn-close {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 9;
      background: none;
      opacity: 1;
      outline: none;
      box-shadow: none; }
      .news-popup.modal .modal-content .btn-close i {
        font-size: 20px;
        color: #051830; }
      .news-popup.modal .modal-content .btn-close:focus {
        box-shadow: none; }
    .news-popup.modal .modal-content .modal-body {
      padding: 0px;
      border-radius: 6px; }
      .news-popup.modal .modal-content .modal-body .popup-bg {
        border-radius: 6px 0px 0px 6px; }
        .news-popup.modal .modal-content .modal-body .popup-bg img {
          border-radius: 6px 0px 0px 6px; }
      .news-popup.modal .modal-content .modal-body .popup-content {
        padding: 40px; }
        .news-popup.modal .modal-content .modal-body .popup-content .newsletter {
          padding: 0;
          background-color: inherit;
          text-align: left; }
          .news-popup.modal .modal-content .modal-body .popup-content .newsletter .title {
            font-size: 26px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 18px; }
          .news-popup.modal .modal-content .modal-body .popup-content .newsletter > p {
            margin-bottom: 36px; }
          .news-popup.modal .modal-content .modal-body .popup-content .newsletter .newsletter-box {
            margin-bottom: 36px;
            display: flex; }
            .news-popup.modal .modal-content .modal-body .popup-content .newsletter .newsletter-box .form-control {
              border-color: #e4eaf7;
              border-radius: 6px 0 0 6px;
              margin-bottom: 0; }
            .news-popup.modal .modal-content .modal-body .popup-content .newsletter .newsletter-box button.submit-btn {
              border-radius: 0px 6px 6px 0px;
              font-size: 18px;
              padding: 10px 45px 11px;
              background-color: #051830;
              color: #ffffff;
              width: 250px; }
              .news-popup.modal .modal-content .modal-body .popup-content .newsletter .newsletter-box button.submit-btn:hover {
                background-color: #30c96a;
                color: #ffffff; }
          .news-popup.modal .modal-content .modal-body .popup-content .newsletter .form-check {
            margin-bottom: 32px; }
          .news-popup.modal .modal-content .modal-body .popup-content .newsletter .social-icons {
            display: flex; }
            .news-popup.modal .modal-content .modal-body .popup-content .newsletter .social-icons li {
              margin-right: 35px; }
              .news-popup.modal .modal-content .modal-body .popup-content .newsletter .social-icons li:last-child {
                margin-right: 0px; }
              .news-popup.modal .modal-content .modal-body .popup-content .newsletter .social-icons li a {
                font-size: 22px;
                color: #051830; }
                .news-popup.modal .modal-content .modal-body .popup-content .newsletter .social-icons li a i {
                  font-size: 22px;
                  margin-bottom: 0px;
                  transition: all 0.3s ease-in-out; }
                .news-popup.modal .modal-content .modal-body .popup-content .newsletter .social-icons li a:hover {
                  color: #30c96a; }
                  .news-popup.modal .modal-content .modal-body .popup-content .newsletter .social-icons li a:hover i {
                    color: #30c96a; }

.dropcap {
  font-weight: 500;
  font-size: 90px;
  width: 80px;
  height: 70px;
  line-height: 70px;
  float: left;
  margin-right: 10px; }

/* Back to top */
.progress-wrap {
  position: fixed;
  z-index: 99;
  right: 50px;
  bottom: 50px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px #041f42;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px); }
  .progress-wrap::before {
    position: absolute;
    font-family: "Font Awesome 6 Free";
    content: "\f062";
    text-align: center;
    line-height: 46px;
    opacity: 1;
    height: 46px;
    width: 46px;
    font-size: 16px;
    color: #30c96a; }
  .progress-wrap svg path {
    fill: none; }
  .progress-wrap svg.progress-circle path {
    stroke: #30c96a;
    stroke-width: 4; }

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0); }

/* Loader */
#overlayer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
  background: #ffffff; }

.loader {
  display: inline-block;
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 9999;
  border: 4px solid #30c96a;
  top: 50%;
  left: 50%;
  animation: loader 2s infinite ease; }

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #30c96a;
  animation: loader-inner 2s infinite ease-in; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(180deg); }
  50% {
    transform: rotate(180deg); }
  75% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader-inner {
  0% {
    height: 0%; }
  25% {
    height: 0%; }
  50% {
    height: 100%; }
  75% {
    height: 100%; }
  100% {
    height: 0%; } }

/* Responsive*/
@media (max-width: 1299px) {
  .follow-style-01 .social-box .follower-btn a {
    padding: 2px 10px; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .follow-style-05 .social ul li {
    margin-right: 10px; }
    .follow-style-05 .social ul li a {
      width: 50px;
      height: 50px;
      font-size: 22px; } }

@media (max-width: 1199px) {
  .news-popup.modal .modal-content .modal-body .popup-content {
    padding: 25px 40px; } }

@media (max-width: 991px) {
  #search {
    padding: 16px 0px;
    top: 87px; }
  #search.search-style-03 {
    top: 90px; }
  .video-box {
    margin-right: 0;
    margin-bottom: 30px; }
  .section-title h2 {
    font-size: 24px; }
  .news-popup.modal .modal-content .btn-close {
    background-color: #ffffff;
    padding: 10px; }
  .news-popup.modal .modal-content .modal-body .popup-bg {
    border-radius: 6px 6px 0px 0px; }
    .news-popup.modal .modal-content .modal-body .popup-bg img {
      border-radius: 6px 6px 0px 0px; }
  .news-popup.modal .modal-content .modal-body .popup-content {
    padding: 20px 20px; }
    .news-popup.modal .modal-content .modal-body .popup-content .newsletter .title {
      margin-bottom: 10px; }
    .news-popup.modal .modal-content .modal-body .popup-content .newsletter p {
      margin-bottom: 20px; }
    .news-popup.modal .modal-content .modal-body .popup-content .newsletter .newsletter-box {
      margin-bottom: 20px; }
    .news-popup.modal .modal-content .modal-body .popup-content .newsletter .form-check {
      margin-bottom: 20px; }
  .news-medical-posts .current-list {
    margin-top: 30px; }
  .news-medical-posts .current-news {
    margin-bottom: 30px; }
    .news-medical-posts .current-news li span {
      border-right: inherit !important; }
  .explore-products .table-responsive .table.coin-table {
    margin-bottom: 0; }
  .live-score {
    display: block; }
    .live-score .live-score-left {
      justify-content: center;
      border-radius: 6px 6px 0px 0px; }
    .live-score .live-score-slider {
      width: calc(100% - 0px); }
      .live-score .live-score-slider .arrow-styel-04 .sport-tournament {
        padding: 15px 0; }
        .live-score .live-score-slider .arrow-styel-04 .sport-tournament .tournament-details {
          flex-wrap: wrap;
          width: 85%; }
        .live-score .live-score-slider .arrow-styel-04 .sport-tournament .tournament-score {
          display: block;
          justify-content: center;
          text-align: center; }
          .live-score .live-score-slider .arrow-styel-04 .sport-tournament .tournament-score .team-02.team {
            margin-left: 0; }
          .live-score .live-score-slider .arrow-styel-04 .sport-tournament .tournament-score .team-01.team {
            margin-right: 0; }
          .live-score .live-score-slider .arrow-styel-04 .sport-tournament .tournament-score .team {
            justify-content: center; }
      .live-score .live-score-slider .arrow-styel-04 .owl-nav .owl-prev {
        top: 70px;
        min-height: 138px;
        border-radius: 0 0px 0 6px; }
      .live-score .live-score-slider .arrow-styel-04 .owl-nav .owl-next {
        top: 70px;
        min-height: 138px;
        border-radius: 0 0px 6px 0px; }
  .contact-info {
    padding-right: 0; }
  .follow-style-01 .social-box .follower-btn a {
    padding: 2px 5px;
    font-size: 16px; }
  .banner-section .destination .destination-detail {
    padding: 0 15px; }
    .banner-section .destination .destination-detail .destination-info h4 {
      font-size: 20px; }
    .banner-section .destination .destination-detail .destination-content .blog-post-meta .blog-post-comment {
      margin-right: 6px; }
    .banner-section .destination .destination-detail .destination-content .upload-box .share-box > a {
      margin-left: 6px; }
    .banner-section .destination .destination-detail .destination-content .upload-box .share-box .share-box-social {
      min-width: 120px;
      left: -30px; }
      .banner-section .destination .destination-detail .destination-content .upload-box .share-box .share-box-social li a {
        padding: 10px 10px; }
  .progress-wrap {
    right: 30px;
    bottom: 30px; } }

@media (max-width: 767px) {
  #search {
    top: 0;
    padding: 16px 0px; }
  #search.search-style-02 {
    padding: 31px 0; }
  #search.search-style-03 {
    top: 40px; }
  .breaking-news .news-btn {
    margin-bottom: 15px; }
  .lion-section .lion-content .blog-title.right-blog {
    border-left: 0;
    padding-left: 0;
    margin-bottom: 0; }
  .news-medical-posts .current-list {
    margin-top: 0; }
  .form-info {
    padding: 20px; }
  .live-score .live-score-slider .arrow-styel-04 .sport-tournament .tournament-details {
    width: 70%; }
  .live-score .live-score-slider .arrow-styel-04 .owl-nav .owl-prev {
    top: 80px;
    min-height: 100%; }
  .live-score .live-score-slider .arrow-styel-04 .owl-nav .owl-next {
    top: 80px;
    min-height: 100%; } }

@media (max-width: 575px) {
  #search {
    padding: 11px 0px; }
  .countrys .country-01 {
    text-align: center; }
  .countrys .country-02 {
    text-align: center !important;
    margin-top: 10px; }
  .live-score .live-score-slider .arrow-styel-04 .sport-tournament .tournament-details {
    width: 85%; }
  .live-score .live-score-slider .arrow-styel-04 .sport-tournament .tournament-score .team .team-name-flag {
    margin-right: 5px; }
    .live-score .live-score-slider .arrow-styel-04 .sport-tournament .tournament-score .team .team-name-flag .team-name {
      font-size: 12px; }
  .live-score .live-score-slider .arrow-styel-04 .sport-tournament .tournament-score .team .team-score {
    font-size: 12px;
    margin-right: 5px; }
  .live-score .live-score-slider .arrow-styel-04 .sport-tournament .tournament-score .team .team-overs {
    font-size: 12px; }
  .live-score .live-score-slider .arrow-styel-04 .owl-nav .owl-prev {
    top: 76px; }
  .live-score .live-score-slider .arrow-styel-04 .owl-nav .owl-next {
    top: 76px; }
  .author-detail .author-info .author-box {
    display: block; }
    .author-detail .author-info .author-box h3 {
      margin-bottom: 10px; } }

@media (max-width: 479px) {
  .news-popup.modal .modal-content .modal-body .popup-content .newsletter .newsletter-box .form-control {
    padding-right: 110px; }
  .news-popup.modal .modal-content .modal-body .popup-content .newsletter .newsletter-box button.submit-btn {
    padding: 13px 20px 14px;
    font-size: 14px; }
  /*follow style 05*/
  .follow-style-05 .social ul li {
    margin-right: 20px; }
    .follow-style-05 .social ul li a {
      font-size: 20px;
      width: 45px;
      height: 45px;
      line-height: 45px; }
  .author-detail {
    display: block; }
    .author-detail .author-info {
      margin-left: 0;
      margin-top: 15px; }
  .news-popup.modal .modal-content .modal-body .popup-content .newsletter .newsletter-box {
    display: block; }
    .news-popup.modal .modal-content .modal-body .popup-content .newsletter .newsletter-box .form-control {
      border-radius: 6px;
      margin-bottom: 15px;
      padding-right: 20px; }
    .news-popup.modal .modal-content .modal-body .popup-content .newsletter .newsletter-box button.submit-btn {
      border-radius: 6px;
      width: auto; } }

@media (max-width: 359px) {
  .live-score .live-score-slider .arrow-styel-04 .owl-nav .owl-next {
    top: 87px; }
  .live-score .live-score-slider .arrow-styel-04 .owl-nav .owl-prev {
    top: 87px; } }

/*****************************
  Shortcodes
*****************************/
/*****************************
  Accordion
*****************************/
.accordion .accordion-item {
  border: none;
  padding: 0 12px;
  margin-bottom: 10px; }
  .accordion .accordion-item .accordion-header {
    background: none;
    padding: 0;
    margin-bottom: 0;
    border: none; }
    .accordion .accordion-item .accordion-header:before {
      border-bottom: 1px solid #e4eaf7; }
    .accordion .accordion-item .accordion-header .accordion-button {
      position: relative;
      font-size: 18px;
      font-weight: 700;
      color: #30c96a;
      padding: 10px 0px 10px 30px;
      width: 100%;
      text-align: left;
      border: none; }
      .accordion .accordion-item .accordion-header .accordion-button:focus {
        box-shadow: inherit; }
      .accordion .accordion-item .accordion-header .accordion-button:before {
        line-height: 12px;
        font-size: 8px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        content: "\f068";
        font-family: "Font Awesome 5 Free";
        border: 1px solid #041f42;
        width: 15px;
        height: 15px;
        line-height: 12px;
        border-radius: 50%;
        z-index: 9;
        text-align: center; }
      .accordion .accordion-item .accordion-header .accordion-button:after {
        display: none; }
    .accordion .accordion-item .accordion-header .accordion-button.collapsed {
      color: #041f42; }
    .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):before {
      border: 1px solid #30c96a; }
  .accordion .accordion-item .accordion-collapse {
    border: none; }
    .accordion .accordion-item .accordion-collapse .accordion-body {
      padding: 0;
      border: none;
      color: #5c667d; }

.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #30c96a;
  box-shadow: inherit; }

/*****************************
  Badge
*****************************/
.badge {
  font-size: 12px;
  text-transform: uppercase;
  padding: 0;
  z-index: 1;
  font-weight: 600; }

.badge-small {
  padding: 4px 15px;
  font-weight: 600; }

.badge-medium {
  padding: 6px 15px;
  font-weight: 600; }

.badge-large {
  padding: 7px 15px;
  font-weight: 600; }

/*badge-style-02*/
.badge-style-02 {
  position: relative;
  color: #0d6efd;
  font-weight: 600;
  font-size: 14px;
  margin-left: 6px; }
  .badge-style-02:before {
    content: '';
    height: 2px;
    display: block;
    width: 5px;
    background: #0d6efd;
    top: 50%;
    position: absolute;
    left: -10px;
    margin-right: 0px; }
  .badge-style-02.text-red:before {
    background: #dc3545; }
  .badge-style-02.text-blue:before {
    background: #0d6efd; }
  .badge-style-02.text-yellow:before {
    background: #ffc107; }
  .badge-style-02.text-pink:before {
    background: #d63384; }
  .badge-style-02.text-orange:before {
    background: #fd7e14; }
  .badge-style-02.text-purple:before {
    background: #6610f2; }
  .badge-style-02.text-skyblue:before {
    background: #0dcaf0; }
  .badge-style-02.text-primary:before {
    background: #30c96a; }

/*badge-style-03*/
.badge-style-03 {
  position: relative;
  color: #041f42;
  font-size: 14px;
  margin-left: 52px;
  font-weight: 700; }
  .badge-style-03:before {
    content: '';
    height: 2px;
    display: block;
    width: 20px;
    background: #041f42;
    top: 6px;
    position: absolute;
    left: -32px;
    margin-right: 0px; }

/*****************************
  Banner
*****************************/
.banner-content {
  padding-top: 200px; }

/*****************************
  Banner style 02
*****************************/
.destination-list-item {
  display: flex;
  position: relative;
  flex-wrap: wrap; }

.destination-item {
  width: 25%;
  position: relative; }
  .destination-item:before {
    content: "";
    background: rgba(255, 255, 255, 0.1);
    height: 100%;
    width: 2px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px); }
  .destination-item .destination {
    z-index: 2;
    background: rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out; }
  .destination-item .destination-img img {
    opacity: 0; }

.destination-item.active .destination {
  background: transparent;
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px); }
  .destination-item.active .destination .destination-detail {
    bottom: 20px; }
    .destination-item.active .destination .destination-detail .destination-content {
      opacity: 1; }

.destination-item-img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s ease-in-out; }

.destination-item-img.bg-1 {
  background-image: url(../images/banner/11.jpg); }

.destination-item-img.bg-2 {
  background-image: url(../images/banner/10.jpg); }

.destination-item-img.bg-3 {
  background-image: url(../images/banner/12.jpg); }

.destination-item-img.bg-4 {
  background-image: url(../images/banner/13.jpg); }

/* banner-new-style */
.banner-new-style {
  padding: 0 90px;
  margin-top: 100px;
  display: inline-block; }
  .banner-new-style .blog-post.post-style-12 {
    background: #f3f7ff;
    display: flex;
    align-items: center;
    height: 100%; }
    .banner-new-style .blog-post.post-style-12 .blog-details {
      padding: 60px; }
      .banner-new-style .blog-post.post-style-12 .blog-details h4 {
        margin-bottom: 30px; }
      .banner-new-style .blog-post.post-style-12 .blog-details .btn {
        margin-top: 40px; }
  .banner-new-style .banner-new-img {
    height: 100%;
    object-fit: cover; }
    .banner-new-style .banner-new-img img {
      height: 100%;
      width: 100%;
      object-fit: cover; }

@media (max-width: 1430px) {
  .banner-new-style {
    padding: 0 0px; }
    .banner-new-style .banner-new-img img {
      width: 100%; } }

@media (max-width: 991px) {
  .banner-new-style .blog-post.post-style-12 .blog-details {
    padding: 60px 60px 60px 60px; } }

@media (max-width: 767px) {
  .destination-list-item .destination-item {
    width: 50%; }
    .destination-list-item .destination-item .destination-img img {
      height: 350px; }
    .destination-list-item .destination-item .destination .destination-detail .destination-content {
      flex-wrap: wrap;
      margin-top: 8px; }
      .destination-list-item .destination-item .destination .destination-detail .destination-content .upload-box {
        margin-bottom: 6px; }
  .banner-new-style {
    padding: 0;
    margin-top: 90px; }
    .banner-new-style .blog-details {
      padding: 0px 30px 30px 30px; }
    .banner-new-style .banner-new-img img {
      width: 100%; } }

@media (max-width: 575px) {
  .destination-list-item .destination-item {
    width: 100%; }
    .destination-list-item .destination-item .destination-img img {
      height: 200px; }
  .banner-section .destination-list-item .destination .destination-detail {
    padding: 0px 15px; }
    .banner-section .destination-list-item .destination .destination-detail .destination-info span {
      margin-bottom: 6px; }
    .banner-section .destination-list-item .destination .destination-detail .destination-info h4 {
      font-size: 16px;
      line-height: 24px; }
    .banner-section .destination-list-item .destination .destination-detail .destination-content .upload-box .share-box .share-box-social {
      display: flex; }
      .banner-section .destination-list-item .destination .destination-detail .destination-content .upload-box .share-box .share-box-social li a {
        padding: 10px 5px;
        text-align: center; }
  .banner-new-style .blog-post.post-style-12 .blog-details {
    padding: 30px 30px 30px 30px; }
    .banner-new-style .blog-post.post-style-12 .blog-details h4 {
      font-size: 26px;
      line-height: 42px; } }

@supports (-webkit-backdrop-filter: blur(9px)) or (backdrop-filter: blur(9px)) {
  .destination-item:before,
  .destination-item.active .destination {
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px); } }

/*****************************
  Blog Post
*****************************/
.blog-post {
  position: relative; }
  .blog-post .blog-image {
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    perspective: 1px; }
    .blog-post .blog-image img {
      border-radius: 6px;
      position: relative;
      overflow: hidden;
      transition: transform 1s cubic-bezier(0.25, 0.04, 0, 0.93);
      transform: translate3d(0, 0, 0) scale(1.06);
      width: 100%; }
    .blog-post .blog-image:before {
      z-index: 1;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.6) 100%);
      top: auto;
      height: 70%;
      transition: all .35s; }
  .blog-post .blog-post-details .blog-title {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
    .blog-post .blog-post-details .blog-title a {
      background-image: linear-gradient(transparent calc(100% - 1px), currentColor 1px);
      background-image: -ms-linear-gradient(transparent 95%, currentColor 1px);
      background-repeat: no-repeat;
      background-size: 0 100%;
      background-position: 0 -1px;
      transition: all 0.6s cubic-bezier(0.45, 0, 0.14, 1.03); }
      .blog-post .blog-post-details .blog-title a:focus {
        color: inherit; }
      .blog-post .blog-post-details .blog-title a:hover {
        color: inherit; }
  .blog-post .blog-post-details .blog-post-meta {
    display: flex;
    align-items: center;
    margin-top: 10px; }
    .blog-post .blog-post-details .blog-post-meta .blog-post-author a {
      font-size: 16px;
      color: #5c667d; }
      .blog-post .blog-post-details .blog-post-meta .blog-post-author a img {
        height: 30px;
        border-radius: 50%;
        margin: 0px;
        width: auto;
        display: inline-block;
        margin-right: 10px; }
    .blog-post .blog-post-details .blog-post-meta .blog-post-time a {
      color: #5c667d; }
    .blog-post .blog-post-details .blog-post-meta .blog-post-time i {
      margin-right: 10px; }
  .blog-post:hover .blog-title a {
    background-size: 100% 100%;
    color: inherit; }
  .blog-post:hover img {
    transform: translate3d(3%, 0, 0) scale(1.06); }

.blog-post-image img {
  border-radius: 6px; }

/* Post Style 01 */
.blog-post.post-style-01 {
  position: relative;
  cursor: pointer; }
  .blog-post.post-style-01 .blog-post-details {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    bottom: 20px;
    padding: 0px 30px; }
    .blog-post.post-style-01 .blog-post-details a.tag {
      font-size: 12px;
      line-height: 12px;
      color: #ffffff;
      padding: 6px 20px;
      text-transform: uppercase;
      font-weight: bold; }
      .blog-post.post-style-01 .blog-post-details a.tag:focus {
        box-shadow: none; }
    .blog-post.post-style-01 .blog-post-details h3 {
      margin-top: 20px;
      color: #ffffff; }
    .blog-post.post-style-01 .blog-post-details h4 {
      margin-top: 15px;
      color: #ffffff;
      font-weight: bold;
      margin-bottom: 0px;
      font-size: 24px;
      line-height: 32px; }
    .blog-post.post-style-01 .blog-post-details h5 {
      margin-top: 15px;
      color: #ffffff;
      font-weight: bold;
      margin-bottom: 0px;
      font-size: 26px;
      line-height: 32px; }
    .blog-post.post-style-01 .blog-post-details .blog-post-meta {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 10px; }
      .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-author {
        margin-right: 20px;
        margin-bottom: 10px; }
        .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-author a {
          font-size: 16px;
          color: #ffffff; }
          .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-author a img {
            height: 30px;
            border-radius: 50%;
            margin: 0px;
            width: auto;
            display: inline-block;
            margin-right: 10px;
            transform: inherit; }
      .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-time {
        margin-bottom: 10px; }
        .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-time a {
          color: #ffffff; }
        .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-time i {
          margin-right: 10px; }
  .blog-post.post-style-01:hover h3 a {
    background-size: 100% 100%;
    color: #ffffff; }
  .blog-post.post-style-01:hover h4 a {
    background-size: 100% 100%;
    color: #ffffff; }
  .blog-post.post-style-01:hover h5 a {
    background-size: 100% 100%;
    color: #ffffff; }

/* Post Style 02 */
.blog-post.post-style-02 {
  position: relative;
  overflow: hidden; }
  .blog-post.post-style-02 .badge {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 9; }
    .blog-post.post-style-02 .badge:focus {
      box-shadow: none; }
  .blog-post.post-style-02 .blog-post-details {
    margin-top: 15px; }
    .blog-post.post-style-02 .blog-post-details h6 {
      font-size: 22px;
      line-height: 24px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #023a51; }
      .blog-post.post-style-02 .blog-post-details h6 a:hover {
        color: #023a51; }
    .blog-post.post-style-02 .blog-post-details .blog-post-time a {
      color: #5c667d; }
      .blog-post.post-style-02 .blog-post-details .blog-post-time a i {
        margin-right: 10px; }

/* Post Style 03 */
.blog-post.post-style-03 {
  position: relative;
  overflow: hidden; }
  .blog-post.post-style-03 .badge {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 9; }
    .blog-post.post-style-03 .badge:focus {
      box-shadow: none; }
  .blog-post.post-style-03 .blog-post-details {
    margin-top: 15px; }
    .blog-post.post-style-03 .blog-post-details h6 {
      font-size: 24px;
      line-height: 24px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #023a51; }
      .blog-post.post-style-03 .blog-post-details h6 a:hover {
        color: #023a51; }
    .blog-post.post-style-03 .blog-post-details .blog-post-meta {
      display: flex;
      align-items: center;
      margin-top: 10px; }
      .blog-post.post-style-03 .blog-post-details .blog-post-meta .blog-post-author a {
        font-size: 16px;
        color: #5c667d; }
        .blog-post.post-style-03 .blog-post-details .blog-post-meta .blog-post-author a img {
          height: 20px;
          border-radius: 50%;
          margin: 0px;
          width: auto;
          display: inline-block;
          margin-right: 10px; }
      .blog-post.post-style-03 .blog-post-details .blog-post-meta .blog-post-time {
        margin-left: 10px; }
        .blog-post.post-style-03 .blog-post-details .blog-post-meta .blog-post-time a {
          color: #5c667d; }
          .blog-post.post-style-03 .blog-post-details .blog-post-meta .blog-post-time a i {
            margin-right: 10px;
            color: #30c96a; }
    .blog-post.post-style-03 .blog-post-details p {
      margin-top: 10px; }
  .blog-post.post-style-03:hover .blog-post-author a img {
    transform: translate3d(0%, 0, 0) scale(1); }

/* Post Style 04 */
.blog-post.post-style-04 {
  display: flex;
  margin-bottom: 15px;
  align-items: center; }
  .blog-post.post-style-04 .blog-image {
    min-width: 130px;
    width: 130px;
    margin-right: 25px;
    border-radius: 6px; }
    .blog-post.post-style-04 .blog-image img {
      border-radius: 6px;
      position: relative;
      overflow: hidden;
      transition: transform 1s cubic-bezier(0.25, 0.04, 0, 0.93);
      transform: translate3d(0, 0, 0) scale(1.06); }
      .blog-post.post-style-04 .blog-image img:hover {
        transform: translate3d(3%, 0, 0) scale(1.06); }
    .blog-post.post-style-04 .blog-image:before {
      background: rgba(5, 24, 48, 0.1);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 9; }
  .blog-post.post-style-04 .blog-post-details .badge {
    padding: 0px;
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    text-transform: uppercase; }
    .blog-post.post-style-04 .blog-post-details .badge:focus {
      box-shadow: none; }
  .blog-post.post-style-04 .blog-post-details h6 {
    font-size: 18px;
    font-weight: 700;
    margin-top: 4px;
    margin-bottom: 10px;
    color: #023a51;
    line-height: 24px; }
    .blog-post.post-style-04 .blog-post-details h6 a:hover {
      color: #023a51; }
  .blog-post.post-style-04 .blog-post-details .blog-post-time a {
    color: #5c667d; }
    .blog-post.post-style-04 .blog-post-details .blog-post-time a i {
      margin-right: 10px;
      color: #5c667d; }
  .blog-post.post-style-04 .blog-post-details .blog-post-meta {
    margin-top: 0; }
  .blog-post.post-style-04:hover img {
    transform: translate3d(3%, 0, 0) scale(1.06); }

/* Post Style 05 */
.blog-post.post-style-05 {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .blog-post.post-style-05:last-child {
    margin-bottom: 0px; }
  .blog-post.post-style-05 .blog-post-date {
    flex: 0 0 13%; }
    .blog-post.post-style-05 .blog-post-date a {
      color: #5c667d;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase; }
    .blog-post.post-style-05 .blog-post-date h2 {
      font-size: 36px;
      line-height: 36px;
      font-weight: 900;
      margin-bottom: 0;
      margin-top: 7px; }
  .blog-post.post-style-05 .blog-image {
    min-width: 160px;
    width: 160px;
    margin-right: 30px;
    position: relative; }
    .blog-post.post-style-05 .blog-image img {
      border-radius: 6px;
      position: relative; }
    .blog-post.post-style-05 .blog-image .video-icon {
      position: absolute;
      z-index: 9;
      bottom: 10px;
      right: 10px; }
      .blog-post.post-style-05 .blog-image .video-icon a i {
        background: #ffffff;
        padding: 5.5px 4.6px;
        border-radius: 6px;
        line-height: 16px; }
    .blog-post.post-style-05 .blog-image:before {
      background: rgba(5, 24, 48, 0.09);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 9; }
  .blog-post.post-style-05 .blog-post-details h6 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 8px;
    color: #023a51;
    line-height: 24px; }
    .blog-post.post-style-05 .blog-post-details h6 a:hover {
      color: #023a51; }
  .blog-post.post-style-05 .blog-post-details .blog-view a {
    color: #5c667d; }
    .blog-post.post-style-05 .blog-post-details .blog-view a:last-child {
      padding-left: 35px;
      position: relative; }
      .blog-post.post-style-05 .blog-post-details .blog-view a:last-child:before {
        content: "";
        position: absolute;
        background: #ffffff;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        left: 15px;
        top: 9px; }

.video-section .blog-style-vertical {
  overflow: auto;
  height: 550px;
  padding-right: 20px; }
  .video-section .blog-style-vertical .blog-post.post-style-05 .blog-post-date a {
    color: #ffffff; }
  .video-section .blog-style-vertical .blog-post.post-style-05 .blog-post-date h2 {
    color: #ffffff; }
  .video-section .blog-style-vertical .blog-post.post-style-05 .blog-post-details h6 {
    color: #ffffff; }
    .video-section .blog-style-vertical .blog-post.post-style-05 .blog-post-details h6 a:hover {
      background-size: 100% 100%;
      color: #ffffff; }
  .video-section .blog-style-vertical .blog-post.post-style-05 .blog-post-details .blog-view a {
    color: #ffffff; }
  .video-section .blog-style-vertical .blog-post.post-style-05:hover {
    background-size: 100% 100%;
    color: #ffffff; }
  .video-section .blog-style-vertical::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #22364f;
    border-radius: 100px; }
  .video-section .blog-style-vertical::-webkit-scrollbar {
    width: 3px;
    background-color: #5d6e83;
    border-radius: 100px; }
  .video-section .blog-style-vertical::-webkit-scrollbar-thumb {
    background-color: #5d6e83; }

/* Post Style 06 */
.blog-post.post-style-06 {
  border: 1px solid #e4eaf7;
  border-radius: 6px; }
  .blog-post.post-style-06 .blog-image {
    border-radius: 6px 6px 0px 0px; }
  .blog-post.post-style-06 .blog-details {
    padding: 23px 15px; }
    .blog-post.post-style-06 .blog-details .blog-post-details {
      text-align: center; }
      .blog-post.post-style-06 .blog-details .blog-post-details .blog-title {
        margin: 20px 0 15px 0;
        font-size: 18px;
        font-weight: 700; }
      .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03 {
        color: #0d6efd;
        margin-left: 0; }
        .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03:before {
          background: #0d6efd; }
      .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-red {
        color: #dc3545;
        margin-left: 0; }
        .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-red:before {
          background: #dc3545; }
      .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-blue {
        color: #0d6efd;
        margin-left: 0; }
        .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-blue:before {
          background: #0d6efd; }
      .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-yellow {
        color: #ffc107;
        margin-left: 0; }
        .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-yellow:before {
          background: #ffc107; }
      .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-pink {
        color: #d63384;
        margin-left: 0; }
        .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-pink:before {
          background: #d63384; }
      .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-orange {
        color: #fd7e14;
        margin-left: 0; }
        .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-orange:before {
          background: #fd7e14; }
      .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-purple {
        color: #6610f2;
        margin-left: 0; }
        .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-purple:before {
          background: #6610f2; }
      .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-skyblue {
        color: #0dcaf0;
        margin-left: 0; }
        .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-skyblue:before {
          background: #0dcaf0; }
      .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-light {
        color: #e4eaf7;
        margin-left: 0; }
        .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-light:before {
          background: #e4eaf7; }
      .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-dark {
        color: #051830;
        margin-left: 0; }
        .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-dark:before {
          background: #051830; }
      .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-primary {
        color: #30c96a;
        margin-left: 0; }
        .blog-post.post-style-06 .blog-details .blog-post-details .badge-style-03.text-primary:before {
          background: #30c96a; }
      .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta {
        justify-content: center;
        padding-bottom: 15px;
        border-bottom: 1px solid #e4eaf7;
        margin-bottom: 10px; }
        .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-time {
          margin-right: 15px; }
          .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-time i {
            margin-right: 10px; }
        .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-comment {
          margin-right: 15px; }
          .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-comment a {
            color: #5c667d; }
            .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-comment a i {
              margin-right: 10px; }
        .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box {
          position: relative;
          z-index: 9; }
          .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box a {
            color: #5c667d; }
            .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box a i {
              margin-right: 10px; }
          .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            left: 50%;
            bottom: 100%;
            background: #30c96a;
            padding: 10px;
            transition: all 0.3s ease-in-out;
            border-radius: 3px;
            transform: translate(-50%, 0px);
            display: flex;
            justify-content: center;
            min-width: 150px; }
            .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social:before {
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 6px 6px 0 6px;
              border-color: #30c96a transparent transparent transparent;
              content: "";
              position: absolute;
              left: 50%;
              bottom: -6px;
              z-index: 99;
              margin-left: -3px; }
            .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li {
              display: flex; }
              .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li a {
                padding: 0 8px;
                color: #ffffff;
                font-size: 12px; }
                .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li a:last-child {
                  padding-right: 0; }
          .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box:hover .share-box-social {
            opacity: 1;
            visibility: visible;
            margin-bottom: 6px; }
    .blog-post.post-style-06 .blog-details .blog-content p {
      text-align: center;
      margin-bottom: 0; }

/* Post Style 07 */
.blog-post.post-style-07 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #d1d8e6;
  padding: 20px; }
  .blog-post.post-style-07 .blog-post-details {
    width: 60%; }
    .blog-post.post-style-07 .blog-post-details .blog-title {
      font-weight: 600;
      padding-top: 15px;
      margin-bottom: 0;
      font-size: 22px;
      line-height: 30px; }
    .blog-post.post-style-07 .blog-post-details .blog-post-meta {
      display: flex;
      padding-top: 22px;
      flex-wrap: wrap;
      margin-top: 0; }
      .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-user {
        margin-right: 15px; }
        .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-user a {
          color: #5c667d;
          display: flex;
          align-items: center; }
          .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-user a img {
            margin: 0 5px;
            height: 20px;
            border-radius: 50%; }
          .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-user a i {
            margin-right: 10px; }
      .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-time {
        margin-right: 15px; }
        .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-time a {
          color: #5c667d;
          display: flex;
          align-items: center; }
          .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-time a i {
            margin-right: 10px; }
      .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-comment {
        margin-right: 15px; }
        .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-comment a {
          color: #5c667d;
          display: flex;
          align-items: center; }
          .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-comment a i {
            margin-right: 10px; }
      .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-share .share-box {
        position: relative;
        z-index: 9; }
        .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-share .share-box a {
          color: #5c667d; }
          .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-share .share-box a i {
            margin-right: 10px; }
        .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          left: 0;
          bottom: 100%;
          background: #30c96a;
          padding: 10px;
          transition: all 0.3s ease-in-out;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          min-width: 150px; }
          .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social:before {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 6px 0 6px;
            border-color: #30c96a transparent transparent transparent;
            content: "";
            position: absolute;
            left: 50%;
            bottom: -6px;
            z-index: 99;
            margin-left: -3px; }
          .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li {
            display: flex; }
            .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li a {
              padding: 0 8px;
              color: #ffffff;
              font-size: 12px; }
              .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li a:last-child {
                padding-right: 0; }
        .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-share .share-box:hover .share-box-social {
          opacity: 1;
          visibility: visible;
          margin-bottom: 6px; }
      .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-user a img {
        transform: inherit; }
  .blog-post.post-style-07 .post-image {
    overflow: hidden;
    border-radius: 6px;
    flex: 0 0 210px;
    perspective: 1px; }
    .blog-post.post-style-07 .post-image img {
      border-radius: 6px;
      transform: scale(1.001) rotate(0);
      transition: transform .9s ease;
      will-change: transform; }
  .blog-post.post-style-07:hover img {
    transform: scale(1.1) rotate(1.1deg); }

/* Post Style 08 */
.blog-post.post-style-08 {
  border-radius: 6px;
  border: 1px solid #e4eaf7; }
  .blog-post.post-style-08 .blog-image {
    overflow: hidden;
    border-radius: 6px 6px 0px 0px; }
    .blog-post.post-style-08 .blog-image img {
      border-radius: 6px 6px 0px 0px;
      transform: scale(1.001) rotate(0);
      transition: transform .9s ease;
      will-change: transform; }
    .blog-post.post-style-08 .blog-image span {
      font-weight: bold;
      position: absolute;
      top: 20px;
      left: 20px; }
  .blog-post.post-style-08 .blog-details {
    padding: 25px 15px;
    position: relative; }
    .blog-post.post-style-08 .blog-details .author-img {
      height: 50px;
      flex: 0 0 50px;
      width: 50px;
      border: 4px solid #ffffff;
      border-radius: 100px;
      position: absolute;
      z-index: 9;
      left: 0;
      top: -10%;
      right: 20px;
      display: flex;
      margin-left: auto; }
      .blog-post.post-style-08 .blog-details .author-img img {
        border-radius: 100px; }
      .blog-post.post-style-08 .blog-details .author-img i {
        height: 15px;
        width: 15px;
        flex: 0 0 15px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        background: #0d6efd;
        color: #ffffff;
        position: absolute;
        right: 0;
        bottom: 0px; }
    .blog-post.post-style-08 .blog-details .blog-post-details {
      text-align: center; }
      .blog-post.post-style-08 .blog-details .blog-post-details h6 {
        margin: 10px 0 18px 0;
        font-weight: 700; }
      .blog-post.post-style-08 .blog-details .blog-post-details .badge-style-03 {
        color: #0d6efd;
        margin-left: 0; }
        .blog-post.post-style-08 .blog-details .blog-post-details .badge-style-03:before {
          background: #0d6efd; }
      .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta {
        justify-content: center;
        padding-bottom: 27px;
        border-bottom: 1px solid #e4eaf7;
        flex-wrap: wrap; }
        .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-time {
          margin-right: 20px; }
          .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-time i {
            margin-right: 10px; }
        .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-comment {
          margin-right: 20px; }
          .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-comment a {
            color: #5c667d; }
            .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-comment a i {
              margin-right: 10px; }
        .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box {
          position: relative;
          z-index: 9; }
          .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box a {
            color: #5c667d; }
            .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box a i {
              margin-right: 10px; }
          .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            left: 50%;
            bottom: 100%;
            background: #30c96a;
            padding: 10px;
            transition: all 0.3s ease-in-out;
            border-radius: 3px;
            transform: translate(-50%, 0px);
            display: flex;
            justify-content: center;
            min-width: 150px; }
            .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social:before {
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 6px 6px 0 6px;
              border-color: #30c96a transparent transparent transparent;
              content: "";
              position: absolute;
              left: 50%;
              bottom: -6px;
              z-index: 99;
              margin-left: -3px; }
            .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li {
              display: flex; }
              .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li a {
                padding: 0 8px;
                color: #ffffff;
                font-size: 12px; }
                .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li a:last-child {
                  padding-right: 0; }
          .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box:hover .share-box-social {
            opacity: 1;
            visibility: visible;
            margin-bottom: 6px; }
    .blog-post.post-style-08 .blog-details .blog-content {
      padding-top: 20px; }
      .blog-post.post-style-08 .blog-details .blog-content p {
        text-align: center;
        margin-bottom: 10px; }
  .blog-post.post-style-08:hover .blog-image img {
    transform: scale(1.1) rotate(1.1deg); }
  .blog-post.post-style-08:hover .blog-details .author-img img {
    transform: inherit; }

/* Post Style 09 */
.blog-post.post-style-09 .blog-image {
  position: relative;
  border-radius: 6px; }
  .blog-post.post-style-09 .blog-image img {
    border-radius: 6px; }

.blog-post.post-style-09 .blog-post-details {
  position: absolute;
  background: #ffffff;
  margin: -50px 30px 0px 30px;
  z-index: 1;
  position: relative;
  padding: 40px;
  text-align: center;
  border-radius: 6px; }
  .blog-post.post-style-09 .blog-post-details p {
    margin-bottom: 0px; }
  .blog-post.post-style-09 .blog-post-details h5 {
    margin: 10px 0px;
    font-weight: 700; }
    .blog-post.post-style-09 .blog-post-details h5 a:hover {
      color: #023a51; }
    .blog-post.post-style-09 .blog-post-details h5 a:focus {
      color: inherit; }

/* Post Style 10 */
.blog-post.post-style-10 .blog-image:before {
  background: inherit !important; }

.blog-post.post-style-10 .blog-details {
  padding: 13px 0; }
  .blog-post.post-style-10 .blog-details .blog-post-details h6 {
    margin: 10px 0 24px 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700; }
  .blog-post.post-style-10 .blog-details .blog-post-details .badge-style-03 {
    color: #0d6efd;
    margin-left: 32px; }
    .blog-post.post-style-10 .blog-details .blog-post-details .badge-style-03:before {
      background: #0d6efd; }
  .blog-post.post-style-10 .blog-details .blog-content {
    padding-bottom: 15px;
    border-bottom: 2px solid #e4eaf7; }
    .blog-post.post-style-10 .blog-details .blog-content p {
      margin-bottom: 0; }

/* Post Style 11 */
.blog-post.post-style-11 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #d1d8e6; }
  .blog-post.post-style-11 .blog-image {
    overflow: hidden;
    border-radius: 6px 0px 0px 6px;
    width: 415px;
    min-width: 415px; }
    .blog-post.post-style-11 .blog-image img {
      border-radius: 6px 0px 0px 6px;
      transform: scale(1.001) rotate(0);
      transition: transform .9s ease;
      will-change: transform; }
      .blog-post.post-style-11 .blog-image img:hover {
        transform: scale(1.1) rotate(1.1deg); }
  .blog-post.post-style-11 .blog-post-details {
    width: 60%;
    padding: 34px; }
    .blog-post.post-style-11 .blog-post-details span.badge {
      padding: 7px 23px; }
    .blog-post.post-style-11 .blog-post-details h4 {
      font-size: 20px;
      font-weight: 600;
      padding-top: 16px;
      margin-bottom: 0; }
    .blog-post.post-style-11 .blog-post-details .blog-post-meta {
      display: flex;
      padding: 10px 0 20px 0;
      flex-wrap: wrap;
      border-bottom: 1px solid #e4eaf7;
      margin-bottom: 10px; }
      .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-user {
        margin-right: 15px; }
        .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-user a {
          color: #5c667d;
          display: flex;
          align-items: center; }
          .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-user a img {
            margin: 0 5px;
            height: 20px;
            border-radius: 50%; }
          .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-user a i {
            margin-right: 10px; }
      .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-time {
        margin-right: 15px; }
        .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-time a {
          color: #5c667d;
          display: flex;
          align-items: center; }
          .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-time a i {
            margin-right: 10px; }
      .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-comment {
        margin-right: 15px; }
        .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-comment a {
          color: #5c667d;
          display: flex;
          align-items: center; }
          .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-comment a i {
            margin-right: 10px; }
      .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-share .share-box {
        position: relative;
        z-index: 9; }
        .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-share .share-box a {
          color: #5c667d; }
          .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-share .share-box a i {
            margin-right: 10px; }
        .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          left: 50%;
          bottom: 100%;
          background: #30c96a;
          padding: 10px;
          transition: all 0.3s ease-in-out;
          border-radius: 3px;
          transform: translate(-50%, 0px);
          display: flex;
          justify-content: center;
          min-width: 150px; }
          .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social:before {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 6px 0 6px;
            border-color: #30c96a transparent transparent transparent;
            content: "";
            position: absolute;
            left: 50%;
            bottom: -6px;
            z-index: 99;
            margin-left: -3px; }
          .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li {
            display: flex; }
            .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li a {
              padding: 0 8px;
              color: #ffffff;
              font-size: 12px; }
              .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li a:last-child {
                padding-right: 0; }
        .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-share .share-box:hover .share-box-social {
          opacity: 1;
          visibility: visible;
          margin-bottom: 6px; }
    .blog-post.post-style-11 .blog-post-details p {
      margin-bottom: 20px; }
  .blog-post.post-style-11:hover .blog-image img {
    transform: translate3d(3%, 0, 0) scale(1.06); }
  .blog-post.post-style-11:hover .blog-post-details h4 a {
    background-size: 100% 100%; }
  .blog-post.post-style-11:hover .blog-post-details .blog-post-meta .blog-post-user a img {
    transform: translate3d(0, 0, 0) scale(1); }

/* Post Style 12 */
.blog-post.post-style-12 .blog-details {
  padding: 40px; }
  .blog-post.post-style-12 .blog-details .blog-post-meta {
    display: flex;
    padding: 20px 0 29px 0;
    flex-wrap: wrap; }
    .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-user {
      margin-right: 15px; }
      .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-user a {
        color: #5c667d;
        display: flex;
        align-items: center; }
        .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-user a img {
          margin: 0 5px;
          height: 20px;
          border-radius: 50%; }
        .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-user a i {
          margin-right: 10px; }
    .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-time {
      margin-right: 15px; }
      .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-time a {
        color: #5c667d;
        display: flex;
        align-items: center; }
        .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-time a i {
          margin-right: 10px; }
    .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-comment {
      margin-right: 15px; }
      .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-comment a {
        color: #5c667d;
        display: flex;
        align-items: center; }
        .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-comment a i {
          margin-right: 10px; }
    .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-share .share-box {
      position: relative;
      z-index: 1; }
      .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-share .share-box a {
        color: #5c667d; }
        .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-share .share-box a i {
          margin-right: 10px; }
      .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-share .share-box .share-box-social {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 50%;
        bottom: 100%;
        background: #30c96a;
        padding: 10px;
        transition: all 0.3s ease-in-out;
        border-radius: 3px;
        transform: translate(-50%, 0px);
        display: flex;
        justify-content: center;
        min-width: 150px; }
        .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-share .share-box .share-box-social:before {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 6px 0 6px;
          border-color: #30c96a transparent transparent transparent;
          content: "";
          position: absolute;
          left: 50%;
          bottom: -6px;
          z-index: 99;
          margin-left: -3px; }
        .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-share .share-box .share-box-social li {
          display: flex; }
          .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-share .share-box .share-box-social li a {
            padding: 0 8px;
            color: #ffffff;
            font-size: 12px; }
            .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-share .share-box .share-box-social li a:last-child {
              padding-right: 0; }
      .blog-post.post-style-12 .blog-details .blog-post-meta .blog-post-share .share-box:hover .share-box-social {
        opacity: 1;
        visibility: visible;
        margin-bottom: 6px; }
  .blog-post.post-style-12 .blog-details h4 {
    font-size: 36px;
    line-height: 52px;
    font-weight: 900;
    margin-bottom: 0; }

/* Post Style 13 */
.blog-post.post-style-13 {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #d1d8e6; }
  .blog-post.post-style-13 .blog-post-details .badge {
    margin-bottom: 12px;
    font-weight: 700; }
  .blog-post.post-style-13 .blog-post-details .blog-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px; }
  .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-time {
    margin-right: 15px; }
  .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-comment {
    margin-right: 15px; }
    .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-comment a {
      color: #5c667d;
      display: flex;
      align-items: center; }
      .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-comment a i {
        margin-right: 10px; }
  .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-share .share-box {
    position: relative;
    z-index: 9; }
    .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-share .share-box a {
      color: #5c667d; }
      .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-share .share-box a i {
        margin-right: 10px; }
    .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      left: 50%;
      bottom: 100%;
      background: #30c96a;
      padding: 10px;
      transition: all 0.3s ease-in-out;
      border-radius: 3px;
      transform: translate(-50%, 0px);
      display: flex;
      justify-content: center;
      min-width: 150px; }
      .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social:before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: #30c96a transparent transparent transparent;
        content: "";
        position: absolute;
        left: 50%;
        bottom: -6px;
        z-index: 99;
        margin-left: -3px; }
      .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li {
        display: flex; }
        .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li a {
          padding: 0 8px;
          color: #ffffff;
          font-size: 12px; }
          .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social li a:last-child {
            padding-right: 0; }
    .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-share .share-box:hover .share-box-social {
      opacity: 1;
      visibility: visible;
      margin-bottom: 6px; }

.blog-post.banner-post-style {
  padding: 100px; }
  .blog-post.banner-post-style .blog-post-meta {
    display: flex;
    padding: 20px 0 30px 0;
    flex-wrap: wrap; }
    .blog-post.banner-post-style .blog-post-meta .blog-post-user {
      margin-right: 15px; }
      .blog-post.banner-post-style .blog-post-meta .blog-post-user a {
        color: #5c667d;
        display: flex;
        align-items: center; }
        .blog-post.banner-post-style .blog-post-meta .blog-post-user a img {
          margin: 0 5px;
          height: 20px;
          border-radius: 50%; }
        .blog-post.banner-post-style .blog-post-meta .blog-post-user a i {
          margin-right: 10px; }
    .blog-post.banner-post-style .blog-post-meta .blog-post-time {
      margin-right: 15px; }
      .blog-post.banner-post-style .blog-post-meta .blog-post-time a {
        color: #5c667d;
        display: flex;
        align-items: center; }
        .blog-post.banner-post-style .blog-post-meta .blog-post-time a i {
          margin-right: 10px; }
    .blog-post.banner-post-style .blog-post-meta .blog-post-comment {
      margin-right: 15px; }
      .blog-post.banner-post-style .blog-post-meta .blog-post-comment a {
        color: #5c667d;
        display: flex;
        align-items: center; }
        .blog-post.banner-post-style .blog-post-meta .blog-post-comment a i {
          margin-right: 10px; }
    .blog-post.banner-post-style .blog-post-meta .blog-post-share .share-box {
      position: relative;
      z-index: 9; }
      .blog-post.banner-post-style .blog-post-meta .blog-post-share .share-box a {
        color: #5c667d; }
        .blog-post.banner-post-style .blog-post-meta .blog-post-share .share-box a i {
          margin-right: 10px; }
      .blog-post.banner-post-style .blog-post-meta .blog-post-share .share-box .share-box-social {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 50%;
        bottom: 100%;
        background: #30c96a;
        padding: 10px;
        transition: all 0.3s ease-in-out;
        border-radius: 3px;
        transform: translate(-50%, 0px);
        display: flex;
        justify-content: center;
        min-width: 150px; }
        .blog-post.banner-post-style .blog-post-meta .blog-post-share .share-box .share-box-social:before {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 6px 0 6px;
          border-color: #30c96a transparent transparent transparent;
          content: "";
          position: absolute;
          left: 50%;
          bottom: -6px;
          z-index: 99;
          margin-left: -3px; }
        .blog-post.banner-post-style .blog-post-meta .blog-post-share .share-box .share-box-social li {
          display: flex; }
          .blog-post.banner-post-style .blog-post-meta .blog-post-share .share-box .share-box-social li a {
            padding: 0 8px;
            color: #ffffff;
            font-size: 12px; }
            .blog-post.banner-post-style .blog-post-meta .blog-post-share .share-box .share-box-social li a:last-child {
              padding-right: 0; }
      .blog-post.banner-post-style .blog-post-meta .blog-post-share .share-box:hover .share-box-social {
        opacity: 1;
        visibility: visible;
        margin-bottom: 6px; }
  .blog-post.banner-post-style .blog-title {
    font-size: 62px;
    font-weight: 800;
    line-height: 82px;
    color: #000000;
    margin-bottom: 30px; }
  .blog-post.banner-post-style .blog-post-content p {
    margin-bottom: 35px;
    font-size: 16px;
    line-height: 26px;
    font-weight: normal;
    color: #5c667d; }

/*Madical Section*/
.madical-section .blog-post.post-style-07 {
  padding: 11px 13px; }
  .madical-section .blog-post.post-style-07 .post-image {
    flex: 0 0 135px;
    margin-right: 25px;
    perspective: 1px; }
  .madical-section .blog-post.post-style-07 .blog-post-details {
    width: inherit; }
    .madical-section .blog-post.post-style-07 .blog-post-details .badge-small {
      padding: 4px 12px;
      font-size: 10px; }
    .madical-section .blog-post.post-style-07 .blog-post-details .blog-title {
      padding-top: 8px;
      font-size: 16px;
      line-height: 20px; }
    .madical-section .blog-post.post-style-07 .blog-post-details .blog-post-meta {
      padding-top: 9px; }
      .madical-section .blog-post.post-style-07 .blog-post-details .blog-post-meta a {
        font-size: 12px; }

/*filters-group */
.filters-group button {
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.5s ease-in-out;
  margin: 0 4px;
  border: none;
  background-color: inherit;
  color: #5c667d;
  text-transform: uppercase; }
  .filters-group button:hover {
    color: #30c96a;
    box-shadow: none;
    outline: none;
    background-size: 300% 100%; }
  .filters-group button:focus {
    box-shadow: none;
    outline: none;
    color: #ffffff; }
  .filters-group button:active {
    box-shadow: none;
    outline: none;
    color: #30c96a; }

.filters-group button.active {
  background-size: 300% 100%;
  color: #30c96a; }

.explore-products .filters-group {
  position: absolute;
  top: -70px;
  right: 6px; }

/*news-post*/
.news-post {
  display: flex;
  align-items: center; }
  .news-post .news-image {
    width: 28px;
    height: 28px;
    flex: 0 0 28px;
    margin-right: 15px;
    border-radius: 4px; }
    .news-post .news-image img {
      border-radius: 4px; }
  .news-post .news-post-details {
    width: 95%; }
    .news-post .news-post-details .news-title {
      font-size: 16px;
      font-weight: normal;
      color: #ffffff;
      margin-bottom: 0;
      line-height: 1; }
      .news-post .news-post-details .news-title a {
        background-image: linear-gradient(transparent calc(100% - 1px), currentColor 1px);
        background-image: -ms-linear-gradient(transparent 95%, currentColor 1px);
        background-repeat: no-repeat;
        background-size: 0 100%;
        background-position: 0 -1px;
        transition: all 0.6s cubic-bezier(0.45, 0, 0.14, 1.03);
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis; }
        .news-post .news-post-details .news-title a:focus {
          color: inherit; }
        .news-post .news-post-details .news-title a:hover {
          background-size: 100% 100%;
          color: #30c96a; }

/*blog-style-01*/
.blog-post-share-box {
  padding: 25px 0px;
  border-bottom: 1px solid #e4eaf7;
  border-top: 1px solid #e4eaf7; }
  .blog-post-share-box .blog-post.post-style-07 {
    padding: 0; }
    .blog-post-share-box .blog-post.post-style-07 .blog-post-details {
      width: 100%; }
  .blog-post-share-box .badges a {
    border: 1px solid #e4eaf7;
    padding: 8px 10px;
    background-color: transparent;
    color: #5c667d; }
    .blog-post-share-box .badges a:hover {
      background-color: #30c96a;
      color: #ffffff; }

.navigation.post-navigation .nav-previous {
  width: 42%;
  margin-right: 3%;
  transition: all 0.5s ease-in-out; }

.navigation.post-navigation .nav-next {
  width: 42%;
  margin-left: 3%;
  text-align: right;
  transition: all 0.5s ease-in-out; }

.navigation.post-navigation .blog-post-nav-media img {
  border-radius: 6px;
  width: 85px;
  height: 85px;
  object-fit: cover; }

.navigation.post-navigation .nav-previous .nav-left {
  width: 70%; }
  .navigation.post-navigation .nav-previous .nav-left .nav-title {
    font-size: 16px;
    font-weight: 500;
    color: #5c667d;
    margin: 5px 20px 0px 0px; }

.navigation.post-navigation .nav-next .nav-right {
  width: 70%; }
  .navigation.post-navigation .nav-next .nav-right .nav-title {
    color: #5c667d;
    font-size: 16px;
    font-weight: 500;
    margin: 5px 0px 0px 20px; }

/* Responsive */
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .banner-section .banner-content {
    padding-top: 150px; }
    .banner-section .banner-content .blog-post.banner-post-style {
      padding: 50px 50px 100px 100px; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-post.post-style-01 .blog-post-details {
    padding: 0px 20px; }
    .blog-post.post-style-01 .blog-post-details h5.blog-title {
      font-size: 20px;
      line-height: 26px; }
    .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-author {
      margin-right: 10px; }
      .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-author a {
        font-size: 14px; }
        .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-author a img {
          height: 25px;
          margin-right: 6px; }
    .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-time a {
      font-size: 14px; }
      .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-time a i {
        margin-right: 6px; }
  .blog-post.post-style-09 .blog-post-details {
    padding: 20px;
    margin: -50px 20px 0px 20px; } }

@media (max-width: 1399px) {
  .filters-group button {
    padding: 12px 25px; }
  .banner-section .banner-content {
    padding-top: 130px; }
    .banner-section .banner-content .blog-post.banner-post-style {
      padding: 50px 50px 100px 100px; }
      .banner-section .banner-content .blog-post.banner-post-style .blog-post-details .blog-title {
        font-size: 52px;
        line-height: 62px; } }

@media (max-width: 1199px) {
  .explore-products .filters-group {
    position: inherit;
    top: 0;
    right: 0;
    margin-bottom: 30px; }
    .explore-products .filters-group button {
      padding: 12px 40px; }
  .blog-post.post-style-11 {
    display: block; }
    .blog-post.post-style-11 .blog-image {
      width: 100%;
      border-radius: 6px 6px 0px 0px; }
      .blog-post.post-style-11 .blog-image img {
        border-radius: 6px 6px 0px 0px; }
    .blog-post.post-style-11 .blog-post-details {
      width: 100%; }
  .banner-section .banner-content {
    padding-top: 100px; }
    .banner-section .banner-content .blog-post.banner-post-style {
      padding: 0px 100px 100px 100px; } }

@media (max-width: 991px) {
  .filters-group button {
    margin: 0 5px 10px; }
  .filters-group button:first-child {
    margin-left: 0; }
  .filters-group button:last-child {
    margin-right: 0; }
  .blog-post.post-style-11 .post-info {
    padding: 10px 20px 10px 20px; }
    .blog-post.post-style-11 .post-info .blog-post-meta {
      padding: 15px 0 15px 0; }
    .blog-post.post-style-11 .post-info p {
      margin-bottom: 15px; }
  /* News Latest Posts*/
  .news-latest-posts .blog-post.post-style-01 {
    margin-bottom: 25px; }
  /* News Sport Posts*/
  .news-sport-posts .blog-post.post-style-03 {
    margin-bottom: 20px; }
  .blog-post.post-style-01 .blog-post-details {
    padding: 0px 20px; }
  /* blog-style-07*/
  .madical-section .blog-post.post-style-07 .post-image {
    flex: 0 0 210px; }
  .madical-section .blog-post.post-style-07 .blog-post-details {
    width: 100%; }
  .banner-section .banner-content {
    padding-top: 20px; }
    .banner-section .banner-content .blog-post.banner-post-style {
      padding: 0px 50px 50px 50px; }
      .banner-section .banner-content .blog-post.banner-post-style .blog-post-details .blog-post-meta {
        padding-bottom: 20px; }
  .blog-post.post-style-12 .blog-details .blog-post-meta {
    padding: 0px 0 29px 0; } }

@media (max-width: 767px) {
  /* blog-style-11*/
  .blog-post.post-style-11 {
    display: block; }
    .blog-post.post-style-11 .post-image {
      width: 100%;
      min-width: 100%; }
    .blog-post.post-style-11 .post-info {
      padding: 30px;
      width: 100%; }
  .news-medical-posts .blog-post.post-style-01 {
    margin-bottom: 30px; }
  .banner-section .banner-content .blog-post.banner-post-style {
    padding: 0px 50px 50px 50px; }
    .banner-section .banner-content .blog-post.banner-post-style .blog-post-details .blog-title {
      font-size: 42px;
      line-height: 52px;
      margin-bottom: 20px; }
    .banner-section .banner-content .blog-post.banner-post-style .blog-post-details .blog-post-content p {
      margin-bottom: 25px; }
  .blog-post.post-style-09 .blog-post-details {
    padding: 20px;
    margin: -50px 20px 0px 20px; } }

@media (max-width: 575px) {
  .blog-post.post-style-01 .blog-post-details h3,
  .blog-post.post-style-01 .blog-post-details h4,
  .blog-post.post-style-01 .blog-post-details h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
    line-height: 24px; }
  .blog-post.post-style-04 {
    display: block; }
    .blog-post.post-style-04 .blog-image {
      width: 100%;
      margin-bottom: 15px; }
  .blog-post.post-style-05 {
    display: block;
    margin-bottom: 35px; }
    .blog-post.post-style-05 .blog-image {
      width: 100%;
      margin-bottom: 20px; }
    .blog-post.post-style-05 .blog-post-date {
      position: absolute;
      z-index: 9;
      left: 15px;
      top: 15px;
      text-align: center;
      background: #ffffff;
      padding: 10px;
      border-radius: 6px; }
      .blog-post.post-style-05 .blog-post-date h2 {
        font-size: 24px;
        line-height: 16px; }
  .blog-post.post-style-11 .blog-image {
    min-width: 100%; }
  .blog-post.post-style-11 .blog-post-details {
    padding: 20px; }
  .blog-post.post-style-07 {
    display: block; }
    .blog-post.post-style-07 .blog-post-details {
      width: 100%; }
    .blog-post.post-style-07 .post-image {
      margin-top: 20px; }
  .blog-post.post-style-06 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social {
    display: block;
    min-width: 0; }
  .blog-post.post-style-07 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social {
    display: block;
    min-width: 0; }
  .blog-post.post-style-08 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social {
    display: block;
    min-width: 0; }
  .blog-post.post-style-11 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social {
    display: block;
    min-width: 0; }
  .blog-post.post-style-12 .blog-details .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social {
    display: block;
    min-width: 0; }
  .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-share .share-box .share-box-social {
    display: block;
    min-width: 0; }
  /* blog-style-07*/
  .madical-section .blog-post.post-style-07 {
    padding: 20px; }
    .madical-section .blog-post.post-style-07 .post-image {
      margin: 0; }
    .madical-section .blog-post.post-style-07 .blog-post-details {
      margin-top: 15px; }
  .video-section .blog-style-vertical .blog-post.post-style-05 .blog-post-date a {
    color: #5c667d; }
  .video-section .blog-style-vertical .blog-post.post-style-05 .blog-post-date h2 {
    color: #023a51; }
  .banner-section .banner-content .blog-post.banner-post-style {
    padding: 0px 30px 30px 30px; }
    .banner-section .banner-content .blog-post.banner-post-style .blog-post-details .blog-title {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 10px; }
    .banner-section .banner-content .blog-post.banner-post-style .blog-post-details .blog-post-content p {
      margin-bottom: 15px; }
  .navigation.post-navigation .nav-previous {
    width: 100%;
    margin-right: 0%; }
    .navigation.post-navigation .nav-previous .nav-left {
      width: 100%; }
  .navigation.post-navigation .nav-next {
    width: 100%;
    margin-left: 0%; }
    .navigation.post-navigation .nav-next .nav-right {
      width: 100%; }
  .news-latest-posts.blog-single-04 .blog-post.post-style-01 .blog-post-details {
    bottom: 10px; } }

@media (max-width: 391px) {
  .blog-post.post-style-01 .blog-post-details {
    bottom: 10px;
    padding: 0px 20px; }
    .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-author {
      margin-right: 10px; }
      .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-author a {
        font-size: 14px; }
        .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-author a img {
          height: 25px;
          margin-right: 6px; }
    .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-time a {
      font-size: 14px; }
      .blog-post.post-style-01 .blog-post-details .blog-post-meta .blog-post-time a i {
        margin-right: 6px; }
  .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-time {
    margin-right: 10px; }
    .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-time i {
      margin-right: 6px; }
  .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-comment {
    margin-right: 10px; }
    .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-comment a i {
      margin-right: 6px; }
  .blog-post.post-style-13 .blog-post-details .blog-post-meta .blog-post-share .share-box a i {
    margin-right: 6px; }
  .news-latest-posts.blog-single-04 .blog-post.post-style-01 .blog-image img {
    height: 180px; } }

/*****************************
  Button
*****************************/
.btn {
  border: none;
  z-index: 1;
  position: relative;
  font-size: 16px;
  padding: 13px 40px;
  overflow: hidden;
  border-radius: 6px;
  transition: all 0.3s ease-in-out; }
  .btn:focus {
    box-shadow: none;
    outline: none;
    color: #ffffff; }
  .btn:active {
    box-shadow: none;
    outline: none;
    color: #ffffff; }
  .btn:active:focus {
    box-shadow: none; }

.btn-primary {
  background: #30c96a;
  color: #ffffff; }
  .btn-primary:hover {
    color: #ffffff;
    background: #041f42; }
  .btn-primary:focus {
    box-shadow: none;
    outline: none;
    color: #ffffff;
    background: #041f42; }
  .btn-primary:active {
    box-shadow: none;
    outline: none;
    color: #ffffff;
    background: #041f42; }

.btn-secondary {
  background: #041f42;
  color: #ffffff; }
  .btn-secondary:hover {
    color: #ffffff;
    background: #30c96a; }
  .btn-secondary:focus {
    box-shadow: none;
    outline: none;
    color: #ffffff;
    background: #30c96a; }
  .btn-secondary:active {
    box-shadow: none;
    outline: none;
    color: #ffffff;
    background: #30c96a; }

.btn-link {
  padding: 0;
  margin-top: 15px;
  text-decoration: none;
  color: #30c96a;
  font-weight: 600; }
  .btn-link:hover {
    color: #023a51; }

/*****************************
  Category
*****************************/
.category {
  cursor: pointer;
  position: relative;
  background: #e4eaf7;
  border-radius: 6px; }
  .category:before {
    background: transparent; }
  .category .category-image {
    overflow: hidden;
    position: relative;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    height: 80px;
    border-radius: 6px;
    perspective: 1px; }
    .category .category-image img {
      transform: scale(1);
      transition: transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      min-height: 100%;
      object-fit: cover;
      perspective: 1px; }
  .category .category-name {
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    bottom: inherit;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    padding: 0px 30px; }
    .category .category-name h6 {
      display: flex;
      color: #023a51;
      width: 100%;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0px;
      transition: all 0.4s ease-in-out;
      text-transform: uppercase; }
      .category .category-name h6 span {
        font-size: 20px;
        font-weight: bold;
        color: #023a51;
        margin-left: auto;
        transition: all 0.4s ease-in-out; }
  .category:hover .category-image {
    opacity: 1; }
  .category:hover h6 {
    color: #ffffff; }
    .category:hover h6 span {
      color: #ffffff; }
  .category:hover .category-image img {
    transform: scale(1.1); }

/* category-02 */
.category-02 {
  cursor: pointer;
  position: relative;
  border-radius: 6px; }
  .category-02 .category-image {
    overflow: hidden;
    position: relative;
    transition: all 0.4s ease-in-out;
    height: 80px;
    border-radius: 6px; }
    .category-02 .category-image img {
      transform: scale(1);
      transition: transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      min-height: 100%;
      object-fit: cover; }
  .category-02 .category-name {
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    bottom: inherit;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    padding: 0px 30px; }
    .category-02 .category-name .category-content {
      font-size: 20px;
      text-transform: uppercase;
      color: #ffffff; }
    .category-02 .category-name .category-icon {
      font-size: 20px;
      color: #ffffff; }
    .category-02 .category-name .category-count {
      font-size: 20px;
      text-transform: uppercase;
      color: #ffffff; }
  .category-02:hover .category-image img {
    transform: scale(1.1); }

/* category-03 */
.category-03-list {
  display: flex;
  flex-wrap: wrap; }
  .category-03-list li {
    width: 50%; }

.category-03 {
  position: relative;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 15px; }
  .category-03 .category-img {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    perspective: 1px; }
    .category-03 .category-img img {
      border-radius: 6px;
      transform: scale(1);
      transition: transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      min-height: 100%;
      object-fit: cover; }
    .category-03 .category-img:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background-image: url("../images/category/overlay.png");
      top: 0;
      left: 0; }
  .category-03 .category-details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center; }
    .category-03 .category-details .category-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .category-03 .category-details .category-icon {
      margin-bottom: 15px;
      font-size: 30px;
      color: #ffffff;
      display: inline-block;
      width: 100%;
      text-align: center;
      opacity: 0;
      transition: all 0.5s ease-in-out; }
    .category-03 .category-details .category-title {
      font-size: 20px;
      font-weight: normal;
      display: inline-block;
      width: 100%;
      text-align: center;
      opacity: 0;
      transition: all 0.5s ease-in-out; }
      .category-03 .category-details .category-title a {
        color: #ffffff; }
        .category-03 .category-details .category-title a:hover {
          color: #ffffff; }
    .category-03 .category-details .category-count {
      border-radius: 6px;
      position: absolute;
      top: 5px;
      right: 5px;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      background-color: #30c96a;
      color: #ffffff;
      font-size: 16px;
      font-weight: normal;
      height: 35px;
      width: 35px;
      display: flex;
      flex: 0 0 35px;
      align-items: center;
      justify-content: center; }
  .category-03:hover .category-img img {
    transform: scale(1.1) rotate(1.1deg); }
  .category-03:hover .category-details .category-icon,
  .category-03:hover .category-details .category-title,
  .category-03:hover .category-details .category-count {
    opacity: 1; }

@media (max-width: 479px) {
  .category-03-list li {
    width: 100%; }
    .category-03-list li:last-child .category-03 {
      margin-bottom: 0px; }
  .category-03 {
    margin-right: 0 !important;
    margin-left: 0 !important; } }

/*****************************
	Form
*****************************/
.sign-in {
  background-color: #e4eaf7;
  padding: 146px 0px; }
  .sign-in .sign-in-bg {
    height: 100%;
    width: 100%;
    background: url(../images/bg/sign-in-bg.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 6px 0px 0px 6px; }
  .sign-in .sign-in-box {
    padding: 80px 50px;
    background: #ffffff;
    border-radius: 0px 6px 6px 0px; }
    .sign-in .sign-in-box .login-social-media .btn {
      padding: 13px 20px;
      color: #ffffff;
      border-radius: inherit; }
    .sign-in .sign-in-box .login-social-media .btn.google {
      background-color: #cc3333; }
    .sign-in .sign-in-box .login-social-media .btn.twitter {
      background-color: #20b5e6; }
    .sign-in .sign-in-box .login-social-media .btn.Facebook {
      background-color: #3b5998; }
    .sign-in .sign-in-box .login-with {
      position: relative; }
      .sign-in .sign-in-box .login-with:before {
        content: "";
        border-top: 1px solid #dbdbdb;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        z-index: 1; }
      .sign-in .sign-in-box .login-with > span {
        background-color: #ffffff;
        padding: 0 10px;
        position: relative;
        z-index: 2; }
    .sign-in .sign-in-box .form-control {
      padding: 14px 20px;
      height: 50px;
      font-size: 14px;
      border-color: #ededed;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      color: #7c8087;
      border-radius: 6px;
      box-shadow: inherit; }
    .sign-in .sign-in-box form .form-check .form-check-label {
      padding-left: 15px; }
    .sign-in .sign-in-box form .form-check .form-check-input {
      float: inherit; }
    .sign-in .sign-in-box form .sign-btn {
      margin-top: 30px; }
    .sign-in .sign-in-box form ul {
      display: flex;
      justify-content: center;
      margin-top: 20px; }

.sign-up.sign-in {
  padding: 100px 0px; }

/*Contact-form*/
.form-info {
  box-shadow: 1px 1px 5px 1px rgba(4, 31, 66, 0.1); }
  .form-info form .form-control {
    height: 50px;
    border-radius: 6px;
    border-color: transparent; }
  .form-info form textarea.form-control {
    min-height: calc(1.5em + (0.75rem + 2px));
    height: auto !important; }

@media (max-width: 1199px) {
  .sign-in .sign-in-box {
    padding: 50px 30px;
    border-radius: 6px; } }

@media (max-width: 991px) {
  .sign-in {
    padding: 80px 0px; }
    .sign-in .sign-in-box {
      border-radius: 6px; }
  .sign-up.sign-in {
    padding: 80px 0px; } }

@media (max-width: 767px) {
  .sign-in {
    padding: 60px 0px; }
    .sign-in .sign-in-box {
      padding: 30px 30px; }
  .sign-up.sign-in {
    padding: 60px 0px; } }

@media (max-width: 575px) {
  .sign-in {
    padding: 40px 0px; }
    .sign-in .sign-in-box .login-social-media .btn {
      width: 100%; }
  .sign-up.sign-in {
    padding: 40px 0px; } }

/*****************************
  Owl carousel
*****************************/
.owl-carousel .owl-nav {
  opacity: 0;
  transition: all 0.5s ease; }
  .owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    top: 66%;
    left: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #041f42;
    background-color: #ffffff !important;
    box-shadow: 1px 1px 5px 1px rgba(4, 31, 66, 0.1);
    border-radius: 6px;
    transition: all 0.5s ease; }
    .owl-carousel .owl-nav button.owl-prev:hover {
      background: #30c96a !important;
      border-color: #30c96a !important;
      color: #ffffff; }
  .owl-carousel .owl-nav button.owl-next {
    position: absolute;
    top: 66%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #041f42;
    background-color: #ffffff !important;
    box-shadow: 1px 1px 5px 1px rgba(4, 31, 66, 0.1);
    border-radius: 6px;
    transition: all 0.5s ease; }
    .owl-carousel .owl-nav button.owl-next:hover {
      background: #30c96a !important;
      border-color: #30c96a !important;
      color: #ffffff; }

.owl-carousel:hover .owl-nav {
  opacity: 1; }
  .owl-carousel:hover .owl-nav button.owl-prev {
    left: -20px; }
  .owl-carousel:hover .owl-nav button.owl-next {
    right: -20px; }

.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 20px; }
  .owl-carousel .owl-dots .owl-dot {
    height: 22px;
    width: 22px;
    line-height: 22px;
    text-align: center;
    display: inline-block;
    border: 1px solid transparent;
    border-radius: 100%;
    margin: 0 5px; }
    .owl-carousel .owl-dots .owl-dot span {
      height: 12px;
      width: 12px;
      display: inline-block;
      border-radius: 100%;
      background-color: #383838; }
  .owl-carousel .owl-dots .active.owl-dot {
    border: 1px solid #30c96a; }
    .owl-carousel .owl-dots .active.owl-dot span {
      background-color: inherit;
      background-image: linear-gradient(to right, #4968ea, #46aef2, #46aef2, #4968ea); }

/*arrow-styel-02*/
.owl-carousel.arrow-styel-02 .owl-nav {
  opacity: 1; }
  .owl-carousel.arrow-styel-02 .owl-nav button.owl-prev {
    top: -66px;
    right: 40px;
    left: initial; }
  .owl-carousel.arrow-styel-02 .owl-nav button.owl-next {
    top: -66px; }

.owl-carousel.arrow-styel-02:hover .owl-nav button.owl-prev {
  left: initial; }

.owl-carousel.arrow-styel-02:hover .owl-nav button.owl-next {
  right: 0px; }

/*arrow-styel-04*/
.owl-carousel.arrow-styel-04 .owl-nav {
  opacity: 1; }
  .owl-carousel.arrow-styel-04 .owl-nav button {
    background-color: #00193a !important;
    color: #ffffff; }
  .owl-carousel.arrow-styel-04 .owl-nav button.owl-prev {
    top: 29px;
    height: 96px;
    border-radius: 0; }
    .owl-carousel.arrow-styel-04 .owl-nav button.owl-prev .fa-arrow-left-long:before {
      content: "\f053" !important;
      font-family: "Font Awesome 6 Free";
      font-weight: 900; }
  .owl-carousel.arrow-styel-04 .owl-nav button.owl-next {
    top: 29px;
    height: 96px;
    border-radius: 0 6px 6px 0px; }
    .owl-carousel.arrow-styel-04 .owl-nav button.owl-next .fa-arrow-right-long:before {
      content: "\f054" !important;
      font-family: "Font Awesome 6 Free";
      font-weight: 900; }

/*owl-carousel-vertical*/
.owl-carousel.owl-carousel-vertical {
  transform: rotate(90deg); }
  .owl-carousel.owl-carousel-vertical .owl-item .item {
    transform: rotate(-90deg); }

@media (max-width: 1599px) {
  .owl-carousel:hover .owl-nav button.owl-prev {
    left: 0px; }
  .owl-carousel:hover .owl-nav button.owl-next {
    right: 0px; } }

@media (max-width: 570px) {
  .section-title {
    padding-right: 80px; }
    .section-title h2 {
      font-size: 22px; } }

/*****************************
  Range Slider
*****************************/
.irs--flat .irs-line {
  height: 6px; }

.irs--flat .irs-bar {
  background: #30c96a;
  height: 6px; }

.irs--flat .irs-from {
  background: transparent;
  color: #30c96a;
  font-size: 13px;
  font-weight: 600; }
  .irs--flat .irs-from:before {
    display: none; }

.irs--flat .irs-to {
  background: transparent;
  color: #30c96a;
  font-size: 13px;
  font-weight: 600; }
  .irs--flat .irs-to:before {
    display: none; }

.irs--flat .irs-single {
  background: transparent;
  color: #30c96a;
  font-size: 13px;
  font-weight: 600; }
  .irs--flat .irs-single:before {
    display: none; }

.irs--flat .irs-handle > i:first-child {
  background: #ffffff;
  background: #ffffff;
  width: 12px;
  width: 12px;
  height: 12px;
  height: 12px;
  border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #30c96a;
  border: 2px solid #30c96a;
  cursor: pointer;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.irs--flat .irs-handle:hover > i:first-child {
  background: #30c96a; }

.irs--flat .irs-handle.state_hover > i:first-child {
  background: #30c96a; }

/*****************************
Select
*****************************/
.select2-container .select2-selection--single {
  height: auto;
  outline: none; }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0px; }

.select2-container--default .select2-selection--single {
  border-radius: 0px;
  border: none; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #e4eaf7;
    font-weight: normal;
    line-height: inherit;
    text-transform: capitalize; }

.bigdrop {
  min-width: 196px !important; }

.select2-results__options {
  padding: 8px 2px; }

.select2-container {
  width: 100% !important; }

.select2-container--default .select2-results__option--highlighted {
  border-radius: 0;
  transition: all 0.2s ease-in-out; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  transition: all 0.3s ease-in-out; }

input[type="radio"]:radio {
  background-color: #30c96a; }

/* Select Dropdown Arrow */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 14px;
  right: 10px;
  height: 21px;
  width: 14px; }
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: none; }
  .select2-container--default .select2-selection--single .select2-selection__arrow:before {
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    color: #5c667d; }

.select2-container--default .select2-search--dropdown {
  padding: 0; }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #e4eaf7; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #e4eaf7;
  transition: all 0.3s ease-in-out; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: rgba(48, 201, 106, 0.1);
  color: #30c96a;
  transition: all 0.3s ease-in-out; }

.select2-dropdown {
  border: none;
  border-radius: 0px;
  z-index: 9; }

.select-border .select2-container--default .select2-selection--single {
  border: 1px solid #e4eaf7;
  height: 50px;
  line-height: 20px;
  padding: 15px 20px;
  border-radius: 6px; }
  .select-border .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 16px;
    right: 20px; }

.select2-results__option[aria-selected] {
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  font-size: 14px; }

.select2-container--default .select2-results > .select2-results__options {
  border: none; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #5c667d; }

.select2-container--open .select2-dropdown {
  background: #ffffff;
  padding: 7px;
  border: 1px solid rgba(61, 209, 213, 0.1);
  box-shadow: 0px 2px 5px 0px rgba(5, 24, 48, 0.1); }

.select2-search--dropdown .select2-search__field {
  border: 1px solid #e4eaf7;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04); }

.select2-container--default .select2-selection--single {
  height: 50px;
  padding: 15px 20px; }

.custom-control-input:radio {
  background-color: #30c96a; }

.newsletter .search input::placeholder {
  color: #ffffff; }

.form-check .form-check-input {
  float: inherit;
  box-shadow: none; }

.form-check-input:checked {
  background-color: #30c96a;
  border-color: #30c96a;
  box-shadow: inherit; }

/*****************************
  Shop
*****************************/
/* Product */
.product {
  text-align: center;
  border: 1px solid #e4eaf7; }
  .product .product-image {
    position: relative;
    padding: 25px; }
    .product .product-image .product-overlay {
      opacity: 0;
      text-align: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
      z-index: 1;
      margin: 0 auto;
      transition: all 0.5s ease-in-out; }
      .product .product-image .product-overlay .add-to-cart a {
        display: inline-block;
        background: #30c96a;
        color: #ffffff;
        text-transform: capitalize;
        padding: 10px 20px;
        border-radius: 6px;
        font-weight: bold; }
        .product .product-image .product-overlay .add-to-cart a span {
          display: inline-block; }
        .product .product-image .product-overlay .add-to-cart a:hover {
          background: #041f42; }
  .product:hover .product-image .product-overlay {
    opacity: 1; }
  .product .product-description {
    padding: 25px; }
    .product .product-description .product-title {
      margin: 0px 0px 10px; }
      .product .product-description .product-title a {
        font-size: 20px;
        font-weight: bold;
        color: #023a51; }
        .product .product-description .product-title a:hover {
          color: #30c96a; }
    .product .product-description .product-price {
      font-size: 14px;
      font-weight: bold; }
      .product .product-description .product-price del {
        color: #d1d8e6; }
      .product .product-description .product-price ins {
        color: #5c667d;
        text-decoration: none; }
  .product hr {
    margin: 0 25px;
    padding: 0px; }

/* Product Detail */
.shop-single .nav-tabs-02 {
  border-bottom: inherit; }
  .shop-single .nav-tabs-02 .nav-item {
    margin-bottom: 10px;
    margin-right: 10px; }
    .shop-single .nav-tabs-02 .nav-item .nav-link {
      font-size: 16px;
      color: #041f42;
      padding: 12px 30px;
      border-color: #e4eaf7;
      background-color: #e4eaf7;
      border-radius: 6px; }
      .shop-single .nav-tabs-02 .nav-item .nav-link:hover {
        background-color: #30c96a;
        border-bottom: 1px solid #30c96a;
        color: #ffffff; }
    .shop-single .nav-tabs-02 .nav-item .nav-link.active {
      background-color: #30c96a;
      border-bottom: 1px solid #30c96a;
      color: #ffffff; }

.shop-single .tab-content .tab-pane h5 {
  font-weight: 700; }

.shop-single .product-detail h4 {
  font-weight: 700; }

.shop-single .product-detail .product-price-rating .product-price {
  margin-bottom: 20px; }
  .shop-single .product-detail .product-price-rating .product-price h4 {
    font-size: 20px;
    margin-bottom: 0; }
    .shop-single .product-detail .product-price-rating .product-price h4 span {
      font-size: 24px;
      color: #30c96a;
      margin-left: 8px; }

.shop-single .product-detail .product-price-rating .product-rating {
  margin: 20px 0; }
  .shop-single .product-detail .product-price-rating .product-rating i {
    color: #30c96a; }

.shop-single .product-detail .product-price-rating .reviews {
  color: #5c667d; }

.shop-single .product-detail .product-summary {
  margin-top: 30px; }
  .shop-single .product-detail .product-summary a {
    color: #5c667d;
    margin-right: 10px; }
    .shop-single .product-detail .product-summary a i {
      color: #30c96a;
      margin-right: 10px; }
    .shop-single .product-detail .product-summary a:hover {
      color: #30c96a; }

.shop-single .product-detail hr {
  margin: 30px 0; }

.shop-single .product-detail .product-detail-meta {
  margin-bottom: 20px; }
  .shop-single .product-detail .product-detail-meta span {
    display: block;
    margin: 10px 0; }

.shop-single .product-detail .product-detail-social {
  display: flex;
  border-top: 1px solid #e4eaf7;
  border-bottom: 1px solid #e4eaf7;
  padding: 10px 0; }
  .shop-single .product-detail .product-detail-social span {
    font-weight: 800; }
  .shop-single .product-detail .product-detail-social ul {
    padding-left: 15px;
    margin-bottom: 0; }
    .shop-single .product-detail .product-detail-social ul li {
      display: inline-block;
      list-style-type: none;
      float: left;
      margin-right: 20px;
      font-size: 16px; }

.shop-single .product-detail .input-group {
  width: 90px;
  float: left;
  margin-right: 20px; }

.title h4 {
  font-weight: 700; }

.form-group .product-rating {
  color: #30c96a; }

.slider-slick .slider-nav .slick-next:before {
  content: "\f054" !important;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: absolute;
  right: inherit;
  left: 50%;
  top: 50%;
  z-index: 999;
  color: #ffffff;
  font-size: 20px;
  transform: translate(-50%, -50%); }

.slider-slick .slider-nav .slick-prev:before {
  content: "\f053" !important;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999;
  color: #ffffff;
  font-size: 20px;
  transform: translate(-50%, -50%); }

.slider-slick .slider-nav:hover .slick-prev {
  left: 0;
  z-index: 99;
  opacity: 1; }

.slider-slick .slider-nav:hover .slick-next {
  right: 0;
  opacity: 1; }

.slider-slick .slider-nav .slick-prev {
  opacity: 0;
  width: 35px;
  height: 50%;
  background: #30c96a;
  border-radius: 4px;
  color: #023a51;
  transition: all 0.3s ease; }

.slider-slick .slider-nav .slick-next {
  opacity: 0;
  width: 35px;
  border-radius: 4px;
  height: 50%;
  background: #30c96a;
  color: #023a51;
  transition: all 0.3s ease; }

.slider-slick .slider-nav .slick-track .slick-slide {
  margin-left: 5px;
  margin-right: 5px; }

/* commentlist */
.commentlist {
  display: flex; }
  .commentlist .comment-author .avatar.avatar-lg {
    width: 75px;
    height: auto;
    border-radius: 6px; }
  .commentlist .comment-content {
    border: 1px solid #e4eaf7;
    padding: 15px;
    margin-left: 20px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 6px; }
    .commentlist .comment-content .reviews {
      display: flex; }
      .commentlist .comment-content .reviews .rating {
        margin-left: auto; }
        .commentlist .comment-content .reviews .rating i {
          color: #30c96a; }

.form-check-label {
  padding-left: 10px; }

/* Shop Sidebar */
.blog-sidebar {
  padding: 20px 20px; }
  .blog-sidebar .widget {
    margin-bottom: 50px; }
    .blog-sidebar .widget .widget-title {
      margin-bottom: 10px; }
    .blog-sidebar .widget .price-filter {
      display: flex; }
      .blog-sidebar .widget .price-filter a {
        margin-left: auto; }
    .blog-sidebar .widget .custom-control-label {
      line-height: 20px; }
    .blog-sidebar .widget .form-check {
      display: block;
      font-size: 16px;
      margin-bottom: 10px; }
      .blog-sidebar .widget .form-check:last-child {
        margin-bottom: 0; }
      .blog-sidebar .widget .form-check span {
        float: right; }
    .blog-sidebar .widget .social ul li {
      display: inline-block;
      list-style-type: none;
      margin-right: 20px;
      font-size: 16px; }
    .blog-sidebar .widget .tagcloud ul li {
      margin-bottom: 10px;
      display: inline-block; }
      .blog-sidebar .widget .tagcloud ul li a {
        color: #023a51;
        line-height: 32px;
        border: 1px solid #e4eaf7;
        padding: 0 16px;
        display: block; }
        .blog-sidebar .widget .tagcloud ul li a span {
          display: block; }
        .blog-sidebar .widget .tagcloud ul li a:hover {
          background: #30c96a;
          border: 1px solid #30c96a;
          color: #ffffff; }
    .blog-sidebar .widget .widget-categories ul li a {
      font-size: 14px;
      color: #023a51;
      line-height: 32px;
      display: block; }
      .blog-sidebar .widget .widget-categories ul li a:hover {
        color: #30c96a; }

.search-field {
  padding-left: 20px;
  box-shadow: none;
  border: 1px solid #e4eaf7;
  height: 50px;
  border-radius: 6px;
  width: 100%; }

/* Checkout */
.checkout-info {
  padding: 25px 25px 25px 120px;
  background: #d1d8e6;
  position: relative;
  border-radius: 6px; }
  .checkout-info:before {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 25px;
    content: "\f2f6";
    font-size: 60px;
    font-family: "Font Awesome 5 Free";
    z-index: 8;
    color: #023a51;
    font-weight: 600; }

.checkout-info-coupon .checkout-info:before {
  content: "\f3ff"; }

.checkout-info-coupon .checkout-coupon {
  padding: 20px;
  border: 2px dashed #e4eaf7; }

.checkout-review {
  padding: 15px 30px 30px;
  border: 1px solid #e4eaf7;
  border-radius: 6px; }
  .checkout-review .table tr th {
    padding: 15px 0;
    font-size: 18px;
    color: #5c667d; }
  .checkout-review .table tr th.shipping {
    font-size: 14px;
    color: #5c667d; }
  .checkout-review .table tr td {
    padding: 15px 0;
    font-size: 14px;
    color: #5c667d; }
  .checkout-review .table tr td.amount {
    font-size: 18px; }

/* Cart */
.cart-table {
  border-radius: 6px; }
  .cart-table .table {
    border: 1px solid #e4eaf7;
    margin-bottom: 0; }
    .cart-table .table tr th {
      border: none;
      padding: 15px 10px;
      color: #5c667d; }
    .cart-table .table tr td {
      border: none;
      border-top: 1px solid #e4eaf7;
      padding: 15px 10px;
      color: #5c667d;
      vertical-align: middle; }
      .cart-table .table tr td.product-remove {
        padding: 15px 25px;
        text-align: center; }
      .cart-table .table tr td.product-quantity .form-control {
        width: 100px; }
      .cart-table .table tr td a {
        color: #5c667d; }
        .cart-table .table tr td a:hover {
          color: #30c96a; }
      .cart-table .table tr td .form-group {
        margin-bottom: 0px; }
    .cart-table .table thead tr {
      border: none; }
      .cart-table .table thead tr th:last-child {
        border-radius: 6px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; }
      .cart-table .table thead tr th:first-child {
        border-radius: 6px;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
  .cart-table .actions {
    display: flex;
    border: 1px solid #e4eaf7;
    border-top: none;
    padding: 15px 10px; }
    .cart-table .actions .coupon form {
      display: flex;
      align-items: center; }
      .cart-table .actions .coupon form .input-text {
        border: 1px solid #e4eaf7;
        border-radius: 0px;
        height: 48px;
        padding: 10px 20px;
        -webkit-box-shadow: none;
        box-shadow: none;
        font-size: 14px;
        font-weight: 500;
        color: #214d63;
        border-radius: 6px; }
      .cart-table .actions .coupon form .btn {
        padding: 12px 20px;
        margin-left: 5px; }
    .cart-table .actions .update-cart {
      margin-left: auto; }

.cart-table .table td.product-thumbnail img {
  width: 70px;
  height: auto; }

.cart-totals {
  background: #e4eaf7;
  padding: 20px;
  border-radius: 6px; }
  .cart-totals .cart-totals-title {
    margin-bottom: 20px;
    text-align: center; }
  .cart-totals .table tr th {
    text-align: left;
    border: none;
    border-top: 1px solid #e4eaf7;
    vertical-align: top;
    background: #ffffff; }
  .cart-totals .table tr td {
    text-align: right;
    border: none;
    border-top: 1px solid #e4eaf7;
    background: #ffffff; }
  .cart-totals .table tr.shipping .form-group {
    margin-bottom: 0; }
  .cart-totals .table tr.shipping .form-check-label {
    padding-left: 0px; }
  .cart-totals .table tr.shipping p {
    margin-bottom: 0; }
  .cart-totals .table tr.order-total th {
    font-size: 18px;
    font-weight: bold; }
  .cart-totals .table tr.order-total td {
    font-size: 26px;
    font-weight: bold; }
  .cart-totals .table .cart-subtotal th {
    font-size: 18px;
    font-weight: bold;
    border: none; }
  .cart-totals .table .cart-subtotal td {
    font-size: 18px;
    font-weight: bold;
    border: none; }
  .cart-totals .checkout-button {
    width: 100%;
    text-align: center;
    padding: 15px 25px; }

.checkout label {
  margin-bottom: 10px; }

@media (max-width: 767px) {
  .slider-slick .slider-for .slick-prev {
    left: 0px;
    opacity: 0; }
  .slider-slick .slider-for .slick-next {
    right: 0px;
    opacity: 0; }
  .slider-slick .slider-nav .slick-prev {
    left: 0px; }
  .slider-slick .slider-nav .slick-next {
    right: 0px; } }

@media (max-width: 575px) {
  .cart-table .actions {
    display: block; }
    .cart-table .actions .coupon form {
      display: block; }
      .cart-table .actions .coupon form .input-text {
        width: 100%; }
      .cart-table .actions .coupon form .btn {
        margin-top: 15px;
        margin-left: 0px;
        width: 100%; }
    .cart-table .actions .update-cart .btn {
      width: 100%; } }

/*****************************
  Sidebar
*****************************/
.sidebar {
  border: 1px solid #d1d8e6;
  padding: 25px 30px 30px 30px;
  border-radius: 6px; }
  .sidebar .widget {
    margin-bottom: 30px; }
    .sidebar .widget .filters-group {
      position: inherit;
      top: 0px;
      right: 0px; }
    .sidebar .widget:last-child {
      margin-bottom: 0; }
    .sidebar .widget .price-filter {
      display: flex; }
      .sidebar .widget .price-filter a {
        margin-left: auto; }
    .sidebar .widget .sidebar-img img {
      border-radius: 6px; }
  .sidebar .widget.widget-tag .widget-title {
    margin-bottom: 10px; }
  .sidebar .widget.widget-tag ul {
    display: flex;
    flex-wrap: wrap; }
    .sidebar .widget.widget-tag ul li {
      display: inline-block;
      margin-right: 10px;
      margin-top: 10px; }
      .sidebar .widget.widget-tag ul li a {
        padding: 10px 20px;
        display: block;
        color: #041f42;
        background: #e4eaf7;
        border-radius: 6px; }
        .sidebar .widget.widget-tag ul li a:hover {
          background: #041f42;
          color: #ffffff; }
  .sidebar .widget-title {
    border-bottom: 1px solid #d1d8e6;
    padding-bottom: 15px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 22px; }
  .sidebar .sidebar-category ul {
    margin-bottom: 0px; }
    .sidebar .sidebar-category ul li {
      margin-bottom: 15px; }
      .sidebar .sidebar-category ul li:last-child {
        margin-bottom: 0; }
        .sidebar .sidebar-category ul li:last-child .category {
          margin-bottom: 0; }
  .sidebar .sidebar-category .sidebar-img {
    margin-top: 30px; }
    .sidebar .sidebar-category .sidebar-img img {
      border-radius: 6px; }
  .sidebar .news-tab .nav {
    position: inherit;
    top: 0;
    margin-bottom: 20px; }
  .sidebar .voting-widget {
    background-color: #e4eaf7;
    border-radius: 6px;
    border: 5px solid rgba(228, 234, 247, 0.3);
    padding: 25px;
    margin-bottom: 0px; }
    .sidebar .voting-widget .voting-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px; }
    .sidebar .voting-widget .title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px; }
    .sidebar .voting-widget .team-progress .team {
      margin-bottom: 10px;
      position: relative; }
      .sidebar .voting-widget .team-progress .team .progress {
        position: relative;
        height: 30px;
        border-radius: 6px;
        background-color: #ffffff; }
        .sidebar .voting-widget .team-progress .team .progress .percentage {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          color: #023a51; }
        .sidebar .voting-widget .team-progress .team .progress .team-name {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 15px;
          display: flex;
          align-items: center;
          color: #ffffff; }
          .sidebar .voting-widget .team-progress .team .progress .team-name .flags-icon {
            padding-right: 10px; }
    .sidebar .voting-widget .team-progress .team-1 .progress .progress-bar {
      background-color: #fd7e14; }
    .sidebar .voting-widget .team-progress .team-2 {
      margin-bottom: 0px; }
      .sidebar .voting-widget .team-progress .team-2 .progress .progress-bar {
        background-color: #0d6efd; }
  .sidebar .search-widget {
    position: relative; }
    .sidebar .search-widget input[type=search] {
      width: 100%;
      color: #5c667d;
      background: #ffffff;
      border: 1px solid #e4eaf7;
      font-size: 16px;
      height: 50px;
      font-weight: 300;
      border-radius: 6px;
      margin-top: 0px;
      padding-left: 20px;
      padding-right: 60px;
      outline: none;
      text-transform: capitalize; }
    .sidebar .search-widget .btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 13px 20px;
      border-radius: 0px 6px 6px 0px; }
      .sidebar .search-widget .btn i {
        padding-right: 0px; }

/* Responsive */
@media (max-width: 1199px) {
  .sidebar .widget.post-widget .news-tab {
    padding-top: 0; }
  .sidebar .widget.sidebar-category .follow-style-01 {
    margin-bottom: 15px; } }

@media (max-width: 991px) {
  .sidebar {
    margin-top: 30px; } }

@media (max-width: 767px) {
  .sidebar {
    padding: 25px 20px; } }

/*****************************
	Tabs
*****************************/
.news-tab {
  position: relative; }
  .news-tab .nav {
    position: absolute;
    right: 0;
    top: -70px; }
    .news-tab .nav .nav-item {
      margin-right: 25px; }
      .news-tab .nav .nav-item .nav-link {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        color: #5c667d;
        border-bottom: 1px solid transparent;
        padding: 0px;
        border-radius: 0px;
        outline: none; }
        .news-tab .nav .nav-item .nav-link:hover {
          color: #30c96a;
          border-bottom: 1px solid rgba(48, 201, 106, 0.5); }
        .news-tab .nav .nav-item .nav-link:focus {
          color: #5c667d; }
        .news-tab .nav .nav-item .nav-link:focus-visible {
          outline: none; }
      .news-tab .nav .nav-item .nav-link.active {
        background-color: inherit;
        color: #30c96a;
        border-bottom: 1px solid rgba(48, 201, 106, 0.5); }
      .news-tab .nav .nav-item:last-child {
        margin-right: 0px; }
  .news-tab .current-news.blog-title {
    font-size: 18px;
    font-weight: 600;
    padding-right: 24px;
    line-height: 24px;
    position: relative;
    color: #5c667d;
    border-right: 1px solid #e4eaf7; }

/* Responsive */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .news-tab .nav {
    position: inherit;
    top: 0px; } }

@media (max-width: 767px) {
  .news-tab .nav {
    position: inherit;
    top: 0px; }
  .news-tab .current-news.blog-title {
    border-right: none;
    padding-right: 0; }
  .news-medical-posts .current-news {
    margin-bottom: 20px; } }

@media (max-width: 575px) {
  .news-tab {
    padding-top: 0px; }
    .news-tab .nav {
      top: 0; }
      .news-tab .nav .nav-item {
        margin-right: 16px; }
  .news-politics-posts .blog-post.post-style-06 {
    margin-bottom: 30px; } }

@media (max-width: 479px) {
  .news-tab .nav .nav-item {
    margin-right: 10px;
    margin-bottom: 5px; }
    .news-tab .nav .nav-item .nav-link {
      font-size: 12px; } }

/*****************************
  Team
*****************************/
.team {
  text-align: center;
  position: relative; }
  .team .team-img {
    position: relative;
    overflow: hidden; }
    .team .team-img img {
      border-radius: 6px; }
    .team .team-img ul {
      display: flex;
      justify-content: center;
      margin-bottom: 0;
      position: absolute;
      bottom: -40px;
      left: 0;
      right: 0;
      opacity: 1;
      transition: all 0.5s ease-in-out; }
      .team .team-img ul li a {
        display: block;
        margin: 0 5px;
        color: #ffffff;
        background: #30c96a;
        line-height: 40px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        transition: all 0.5s ease-in-out; }
        .team .team-img ul li a:hover {
          color: #ffffff;
          background: #041f42; }
  .team .team-info {
    padding: 25px 0px 0px; }
    .team .team-info .team-name {
      color: #041f42;
      font-size: 20px;
      font-weight: 700; }
      .team .team-info .team-name:hover {
        color: #30c96a; }
    .team .team-info p {
      font-size: 16px;
      margin-bottom: 25px; }
  .team:hover .team-img {
    z-index: 9;
    position: relative; }
    .team:hover .team-img ul {
      bottom: 15px; }
  .team:hover .team-info {
    z-index: 9;
    position: relative; }

/*****************************
  responsive
*****************************/
@media (max-width: 575px) {
  .team .team-img img {
    width: 100%; } }

/*****************************
  Header
*****************************/
.header .navbar {
  padding: 30px 0px; }
  .header .navbar .navbar-brand {
    padding: 0;
    margin-right: 30px; }
  .header .navbar .navbar-nav .nav-item .nav-link {
    font-size: 16px;
    color: #5c667d;
    padding: 10px; }
    .header .navbar .navbar-nav .nav-item .nav-link:hover {
      color: #30c96a; }
    .header .navbar .navbar-nav .nav-item .nav-link i {
      font-size: 12px;
      margin-left: 10px; }
  .header .navbar .navbar-nav .nav-item .nav-link.dropdown-toggle:after {
    display: none; }
  .header .navbar .navbar-nav .nav-item .megamenu-fullwidth {
    min-height: 300px;
    width: calc(100% - 30px);
    left: 25px; }
  .header .navbar .navbar-nav .nav-item .megamenu-screen {
    min-height: 330px; }
  .header .navbar .navbar-nav .nav-item.active .nav-link {
    color: #30c96a; }
  .header .navbar .navbar-nav .dropdown-menu li.active > a {
    background: rgba(48, 201, 106, 0.07);
    color: #30c96a;
    padding-left: 10px;
    border-radius: 6px; }
  .header .navbar .navbar-nav .dropdown-menu li:hover > a {
    background: rgba(48, 201, 106, 0.07);
    color: #30c96a;
    padding-left: 10px;
    border-radius: 6px; }
  .header .navbar .navbar-nav .dropdown-menu li > a:hover {
    background: rgba(48, 201, 106, 0.07);
    color: #30c96a;
    padding-left: 10px;
    border-radius: 6px; }
  .header .navbar .navbar-nav .dropdown-menu .inner-mega-menu .nav-item .dropdown-item {
    font-size: 16px;
    padding: 7px 0px; }
    .header .navbar .navbar-nav .dropdown-menu .inner-mega-menu .nav-item .dropdown-item:active, .header .navbar .navbar-nav .dropdown-menu .inner-mega-menu .nav-item .dropdown-item:hover {
      background: rgba(48, 201, 106, 0.07);
      color: #30c96a;
      padding-left: 10px;
      border-radius: 6px; }
  .header .navbar .navbar-nav .dropdown-menu .inner-mega-menu .nav-item .dropdown-item.active {
    background: rgba(48, 201, 106, 0.07);
    color: #30c96a;
    padding-left: 10px;
    border-radius: 6px; }
  .header .navbar .navbar-nav .dropdown-menu .inner-mega-menu .blog-post.post-style-02 .blog-post-details .blog-title {
    font-size: 18px; }

.header .topbar {
  padding: 15px 0px;
  border-bottom: 1px solid #e4eaf7; }
  .header .topbar .topbar-date {
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid #e4eaf7; }
    .header .topbar .topbar-date .date i {
      margin-right: 6px; }
  .header .topbar .top-menu {
    display: flex;
    align-items: center; }
    .header .topbar .top-menu li {
      list-style-type: none;
      margin-right: 15px; }
      .header .topbar .top-menu li a {
        color: #5c667d;
        font-size: 16px;
        padding: 0;
        font-weight: 500; }
        .header .topbar .top-menu li a:hover {
          color: #30c96a; }
      .header .topbar .top-menu li:last-child {
        margin-right: 0px; }
  .header .topbar .topbar-right {
    display: flex;
    align-items: center; }
  .header .topbar .user {
    margin-right: 20px; }
    .header .topbar .user a {
      color: #5c667d; }
      .header .topbar .user a:hover {
        color: #30c96a; }
      .header .topbar .user a img {
        margin-right: 5px; }
  .header .topbar .news-language {
    margin-right: 20px; }
    .header .topbar .news-language .dropdown-toggle {
      color: #5c667d; }
      .header .topbar .news-language .dropdown-toggle i {
        margin-left: 8px; }
      .header .topbar .news-language .dropdown-toggle:hover {
        color: #30c96a; }
      .header .topbar .news-language .dropdown-toggle:after {
        display: none; }
      .header .topbar .news-language .dropdown-toggle img {
        margin-right: 5px;
        width: 20px;
        height: auto; }
    .header .topbar .news-language .dropdown-menu {
      top: 50% !important;
      padding: 10px;
      box-shadow: 0px 2px 5px 0px rgba(5, 24, 48, 0.1);
      border: none; }
      .header .topbar .news-language .dropdown-menu .dropdown-item {
        color: #5c667d;
        font-size: 16px;
        padding: 5px 0px; }
        .header .topbar .news-language .dropdown-menu .dropdown-item img {
          margin-right: 8px;
          width: 20px;
          height: auto; }
        .header .topbar .news-language .dropdown-menu .dropdown-item:hover {
          color: #30c96a;
          background-color: inherit; }

.header .add-listing {
  display: flex;
  align-items: center; }
  .header .add-listing .header-search {
    margin-right: 20px; }
    .header .add-listing .header-search .search a i {
      font-size: 18px;
      color: #5c667d;
      transition: all 0.3s ease-in-out; }
      .header .add-listing .header-search .search a i:hover {
        color: #30c96a; }
  .header .add-listing .side-menu a {
    cursor: pointer; }
    .header .add-listing .side-menu a i {
      color: #5c667d;
      font-size: 24px; }
    .header .add-listing .side-menu a:hover i {
      color: #30c96a; }
  .header .add-listing .clock {
    background-color: #30c96a;
    display: inline-block;
    color: #ffffff;
    padding: 5px 18px;
    border-radius: 6px;
    font-size: 16px;
    margin-right: 15px;
    min-width: 135px;
    text-align: center; }

.navbar-brand .logo-light {
  display: none; }

.weather {
  display: flex;
  align-items: center;
  margin-right: 20px; }
  .weather .weather-icon {
    margin-right: 15px; }
  .weather .weather-temprecher {
    font-size: 24px;
    font-weight: bold;
    color: #5c667d;
    position: relative;
    margin-right: 15px; }
    .weather .weather-temprecher:before {
      position: absolute;
      content: "";
      height: 7px;
      width: 7px;
      border-radius: 100%;
      border: 2px solid #5c667d;
      right: 0;
      top: 0; }
  .weather .weather-address span {
    display: block; }
  .weather .weather-address .place {
    font-size: 16px;
    color: #5c667d;
    font-family: "Zilla Slab", serif;
    font-weight: 600; }
  .weather .weather-address .date {
    font-size: 12px;
    color: #5c667d; }

.social ul {
  list-style-type: none;
  display: flex;
  align-items: center; }
  .social ul li {
    list-style-type: none;
    margin-right: 15px; }
    .social ul li a {
      color: #5c667d;
      font-size: 16px;
      padding: 0; }
      .social ul li a:hover {
        color: #30c96a; }
    .social ul li:last-child {
      margin-right: 0px; }

/*megamenu*/
.megamenu {
  padding: 20px 20px;
  width: 100%; }
  .megamenu > div > li > ul {
    padding: 0;
    margin: 0; }
    .megamenu > div > li > ul > li {
      list-style: none; }
      .megamenu > div > li > ul > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: 1.428571429;
        color: #5c667d;
        white-space: normal; }
        .megamenu > div > li > ul > li > a:hover {
          text-decoration: none;
          color: #5c667d;
          background-color: #5c667d; }
        .megamenu > div > li > ul > li > a:focus {
          text-decoration: none;
          color: #5c667d;
          background-color: #5c667d; }

.megamenu.dropdown-header {
  color: #30c96a;
  font-size: 18px; }

.dropdown-submenu {
  position: relative;
  padding: 7px 0 7px 0;
  display: block;
  color: #041f42; }

/*Header Transparent*/
.header.header-transparent {
  background-color: transparent; }
  .header.header-transparent .navbar .navbar-nav .nav-item .nav-link {
    color: #ffffff; }
    .header.header-transparent .navbar .navbar-nav .nav-item .nav-link:hover {
      color: #30c96a; }
  .header.header-transparent .navbar .nav-item.active .nav-link {
    color: #30c96a; }
  .header.header-transparent .add-listing .header-search .search a i {
    color: #ffffff; }
  .header.header-transparent .add-listing .header-search .search a:hover i {
    color: #30c96a; }
  .header.header-transparent .add-listing .side-menu a i {
    color: #ffffff; }
  .header.header-transparent .add-listing .side-menu a:hover i {
    color: #30c96a; }
  .header.header-transparent .weather .weather-temprecher {
    color: #ffffff; }
    .header.header-transparent .weather .weather-temprecher:before {
      border: 2px solid #ffffff; }
  .header.header-transparent .weather .weather-address .place {
    color: #ffffff; }
  .header.header-transparent .weather .weather-address .date {
    color: #ffffff; }

/*Header Style 02*/
.header.header-style-02 {
  padding: 0px 90px;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 9; }
  .header.header-style-02 .navbar {
    padding: 20px 0px; }
  .header.header-style-02 .news-headlin {
    width: 27%;
    height: 40px;
    overflow: hidden;
    display: flex;
    position: relative; }
    .header.header-style-02 .news-headlin .news-headlin-title {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      transform: scale(1);
      transition: all 1s ease; }
      .header.header-style-02 .news-headlin .news-headlin-title span {
        animation: scrollText 30s infinite linear;
        font-size: 16px;
        font-weight: normal; }

@keyframes scrollText {
  from {
    transform: translateX(0%); }
  to {
    transform: translateX(-50%); } }

.header-transparent.header-style-02:after {
  z-index: 1;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.6) 100%);
  top: 0;
  height: 100px;
  -webkit-transition: all .35s;
  transition: all .35s;
  z-index: -1; }

/*sticky header*/
.header.is-sticky {
  position: fixed !important;
  width: 100%;
  background: #ffffff;
  box-shadow: 1px 1px 5px 1px rgba(4, 31, 66, 0.1);
  top: 0;
  z-index: 999;
  transition: 0.5s ease-in-out;
  animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both; }
  .header.is-sticky .topbar {
    display: none !important; }
  .header.is-sticky .navbar {
    padding: 20px 0px; }
  .header.is-sticky .add-listing .header-search {
    display: none; }

.header.header-transparent.is-sticky {
  background: #051830; }

@keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.news-headlin {
  margin-right: 30px;
  color: #ffffff;
  font-size: 16px;
  width: 400px; }

/*Header Style 03*/
.header-style-03 {
  width: 50%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  padding: 0px 90px; }

.navbar-nav-style-03 .nav-item .nav-link {
  position: relative;
  padding: 10px;
  color: #5c667d; }
  .navbar-nav-style-03 .nav-item .nav-link i {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
  .navbar-nav-style-03 .nav-item .nav-link:hover {
    color: #30c96a; }

.navbar-nav-style-03 .nav-item ul.dropdown-menu.show {
  position: relative !important;
  border: none;
  transform: none !important; }

.navbar-nav-style-03 .nav-item .dropdown-menu {
  padding: 10px;
  transition: 0.5s ease-in-out;
  background-color: #e4eaf7; }
  .navbar-nav-style-03 .nav-item .dropdown-menu li .dropdown-item {
    font-size: 16px;
    color: #5c667d; }
    .navbar-nav-style-03 .nav-item .dropdown-menu li .dropdown-item:hover {
      background: rgba(48, 201, 106, 0.07);
      color: #30c96a;
      padding-left: 10px; }
  .navbar-nav-style-03 .nav-item .dropdown-menu li.active .dropdown-item {
    background: rgba(48, 201, 106, 0.07);
    color: #30c96a;
    padding-left: 10px; }

.navbar-nav-style-03 .nav-item.active .nav-link {
  color: #30c96a; }

.navbar-nav-style-03 .nav-link.dropdown-toggle:after {
  display: none; }

.offcanvas.offcanvas-start .offcanvas-body .navbar-nav-style-03 .nav-item .dropdown-menu li.active a {
  padding-left: 10px; }

.offcanvas.offcanvas-start {
  width: 800px;
  background: #ffffff;
  border-right: inherit;
  padding: 50px 100px; }
  .offcanvas.offcanvas-start .offcanvas-header .btn-close {
    background: inherit;
    position: relative;
    display: inline-block;
    width: 5px;
    height: 5px;
    overflow: hidden;
    opacity: 1; }
    .offcanvas.offcanvas-start .offcanvas-header .btn-close:before {
      content: '';
      position: absolute;
      height: 4px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #5c667d;
      transform: rotate(45deg);
      transition: all 0.3s ease-in-out; }
    .offcanvas.offcanvas-start .offcanvas-header .btn-close:after {
      content: '';
      position: absolute;
      height: 4px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #5c667d;
      transform: rotate(-45deg);
      transition: all 0.3s ease-in-out; }
    .offcanvas.offcanvas-start .offcanvas-header .btn-close:focus {
      box-shadow: inherit; }
    .offcanvas.offcanvas-start .offcanvas-header .btn-close:hover:before {
      background: #30c96a; }
    .offcanvas.offcanvas-start .offcanvas-header .btn-close:hover:after {
      background: #30c96a; }
  .offcanvas.offcanvas-start .offcanvas-body {
    overflow: auto;
    height: 550px; }
    .offcanvas.offcanvas-start .offcanvas-body ul.navbar-nav-style-03 li.nav-item a {
      padding: 15px 0; }
    .offcanvas.offcanvas-start .offcanvas-body ul.navbar-nav-style-03 li.nav-item .dropdown-menu li a.dropdown-item {
      padding: 10px 0; }
    .offcanvas.offcanvas-start .offcanvas-body ul.navbar-nav-style-03 li.nav-item .dropdown-menu li.active a.dropdown-item {
      padding-left: 10px; }
    .offcanvas.offcanvas-start .offcanvas-body ul.navbar-nav-style-03 li .sidebar-post {
      margin-top: 50px;
      padding-bottom: 50px;
      border-bottom: 1px solid #ebf0f9; }
    .offcanvas.offcanvas-start .offcanvas-body .social-icons {
      display: flex;
      margin-top: 40px; }
      .offcanvas.offcanvas-start .offcanvas-body .social-icons li {
        margin-right: 60px; }
        .offcanvas.offcanvas-start .offcanvas-body .social-icons li:last-child {
          margin-right: 0px; }
        .offcanvas.offcanvas-start .offcanvas-body .social-icons li a {
          font-size: 22px;
          color: #5c667d; }
          .offcanvas.offcanvas-start .offcanvas-body .social-icons li a:hover {
            color: #30c96a; }
    .offcanvas.offcanvas-start .offcanvas-body::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      background-color: #fff;
      border-radius: 100px; }
    .offcanvas.offcanvas-start .offcanvas-body::-webkit-scrollbar {
      width: 3px;
      background-color: #fff;
      border-radius: 100px; }
    .offcanvas.offcanvas-start .offcanvas-body::-webkit-scrollbar-thumb {
      background-color: #fff; }

.offcanvas.offcanvas-start .offcanvas-body .navbar-nav-style-03 .nav-item .dropdown-menu li .dropdown-item:hover {
  padding-left: 10px; }

/*Header Style 04*/
.header.header-style-04 {
  padding: 0px 90px;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 999; }
  .header.header-style-04 .navbar {
    padding: 28px 0px; }

/*Header Style 06*/
.header.header-style-06.is-sticky {
  border-bottom: none; }
  .header.header-style-06.is-sticky .navbar {
    border-bottom: none;
    margin-bottom: 0;
    padding: 10px 0px; }
    .header.header-style-06.is-sticky .navbar .navbar-brand {
      display: inline-block !important; }
    .header.header-style-06.is-sticky .navbar .navbar-nav .nav-item:first-child .nav-link {
      padding-left: 10px; }
  .header.header-style-06.is-sticky .trending .news-headlin {
    width: 450px; }

.header.header-style-06 .topbar {
  padding: 20px 0px; }
  .header.header-style-06 .topbar .topbar-right .news-language {
    margin-right: 0; }

.header.header-style-06 .navbar {
  border-bottom: 1px solid #e4eaf7;
  margin-bottom: 2px;
  padding: 2px 0px;
  background: #041f42; }
  .header.header-style-06 .navbar .navbar-nav .nav-item:first-child .nav-link {
    padding-left: 0px; }
  .header.header-style-06 .navbar .add-listing .search a i {
    color: #ffffff; }
  .header.header-style-06 .navbar .add-listing .side-menu a i {
    color: #ffffff; }

.header.header-style-06 .trending {
  display: flex;
  align-items: center; }
  .header.header-style-06 .trending .title {
    font-size: 16px;
    color: #ffffff;
    padding-right: 15px; }
  .header.header-style-06 .trending .news-headlin {
    width: 630px;
    height: 40px;
    overflow: hidden;
    display: flex;
    position: relative;
    margin-right: 60px; }
    .header.header-style-06 .trending .news-headlin .news-headlin-title {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      transform: scale(1);
      transition: all 1s ease; }
      .header.header-style-06 .trending .news-headlin .news-headlin-title ul {
        display: flex;
        align-items: center;
        animation: scrollText 30s infinite linear; }
        .header.header-style-06 .trending .news-headlin .news-headlin-title ul li {
          font-weight: normal;
          font-size: 16px;
          color: #ffffff;
          position: relative;
          padding-right: 10px; }

.header.header-style-06 .navbar-nav .nav-item .nav-link {
  color: #ffffff; }

@media (min-width: 992px) {
  .header .navbar .dropdown-menu {
    margin: 0px;
    min-width: 200px;
    font-size: 16px;
    border-radius: 6px;
    padding: 20px;
    border-color: transparent;
    left: 0;
    transform: translate3d(-10px, 15px, 0);
    transition: all 0.3s ease-in-out;
    transition-property: opacity, visibility, transform;
    transform-origin: top center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    display: block !important; }
    .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu {
      left: 100%;
      right: auto;
      transform: translate3d(0, 10px, 0); }
    .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu.left-side {
      right: 100%;
      left: auto;
      transform: translate3d(-15px, 10px, 0); }
  .header .navbar .dropdown-menu.megamenu {
    left: 0; }
  .header .navbar .dropdown-menu.megamenu.megamenu-screen {
    width: calc(100% - 20px);
    left: 20px; }
  .header .navbar .dropdown > .dropdown-menu {
    top: 100%;
    margin-top: 0px; }
  .header .navbar .dropdown > .dropdown-menu.megamenu {
    top: 100%; }
  .header .navbar .dropdown:hover > .dropdown-menu {
    transform: translate3d(-10px, 0px, 0);
    visibility: visible;
    opacity: 1; }
  .navbar-nav .dropdown-menu.dropdown-menu-lg {
    min-width: 630px; }
  .navbar-nav .dropdown-menu.dropdown-menu-md {
    min-width: 450px; }
  .navbar-nav .mega-menu {
    position: static; }
    .navbar-nav .mega-menu ul {
      display: none; }
    .navbar-nav .mega-menu:hover ul {
      display: block; }
    .navbar-nav .mega-menu li .inner-mega-menu {
      display: none; }
    .navbar-nav .mega-menu li.selected .inner-mega-menu {
      display: block; }
    .navbar-nav .mega-menu .dropdown-menu li {
      display: flex; }
      .navbar-nav .mega-menu .dropdown-menu li a {
        width: 15%; }
      .navbar-nav .mega-menu .dropdown-menu li .inner-mega-menu {
        position: absolute;
        left: calc(2% - -15%);
        top: 20px;
        right: 20px; }
    .navbar-nav .mega-menu .dropdown-menu a.dropdown-item.active {
      background: rgba(48, 201, 106, 0.07);
      color: #30c96a;
      padding-left: 10px;
      border-radius: 6px; }
    .navbar-nav .mega-menu .dropdown-menu a.dropdown-item:active {
      background: rgba(48, 201, 106, 0.07);
      color: #30c96a;
      padding-left: 10px;
      border-radius: 6px; }
  .navbar-nav li:hover > ul.dropdown-menu {
    visibility: visible;
    opacity: 1; }
  .dropdown-submenu {
    position: relative;
    padding: 7px 0 7px 0;
    display: block;
    color: #041f42;
    font-weight: 400; }
    .dropdown-submenu > .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px; }
  .dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg); }
  .dropdown-menu .dropdown-item {
    padding: 7px 0 7px 0; }
    .dropdown-menu .dropdown-item:focus {
      background-color: transparent; }
  .header.header-style-04 .navbar .navbar-collapse {
    justify-content: center; }
  .categories-mobile {
    display: none; } }

@media (max-width: 1699px) {
  .header.header-style-02 {
    padding: 0px 20px; }
    .header.header-style-02 .news-headlin {
      margin-right: 20px;
      width: 18%; } }

@media (max-width: 1430px) {
  .header.header-style-04 {
    padding: 0px 0px; } }

@media (max-width: 1399px) {
  .header.header-style-02 {
    padding: 0px 10px; }
    .header.header-style-02 .news-headlin {
      width: 16%; }
    .header.header-style-02 .navbar .navbar-brand {
      margin-right: 15px; }
    .header.header-style-02 .navbar .navbar-nav .nav-item .nav-link {
      padding: 10px 6px; }
  .header.header-style-06 .trending .news-headlin {
    width: 500px; }
  .header.header-style-06.is-sticky .trending .news-headlin {
    width: 350px; } }

@media (max-width: 1299px) {
  .header.header-style-02 {
    padding: 0px 15px; }
    .header.header-style-02 .add-listing .header-search {
      margin-right: 10px; }
    .header.header-style-02 .add-listing .clock {
      margin-right: 10px; }
    .header.header-style-02 .weather {
      margin-right: 10px; }
      .header.header-style-02 .weather .weather-icon {
        margin-right: 10px; }
      .header.header-style-02 .weather .weather-temprecher {
        margin-right: 10px; }
    .header.header-style-02 .navbar .navbar-nav .nav-item .nav-link {
      font-size: 14px; }
  .header.header-style-06 .trending .news-headlin {
    width: 370px; }
  .header.header-style-06.is-sticky .trending .title {
    padding-right: 30px; }
  .header.header-style-06.is-sticky .trending .news-headlin {
    width: 250px;
    margin-right: 40px; } }

@media (max-width: 1199px) {
  .header .navbar .navbar-brand {
    margin-right: 10px; }
    .header .navbar .navbar-brand img {
      height: 30px; }
  .header .add-listing .clock {
    margin-right: 10px; }
  .header .add-listing .header-search {
    margin-right: 10px; }
  .weather {
    margin-right: 10px; }
    .weather .weather-icon {
      margin-right: 10px; }
    .weather .weather-temprecher {
      margin-right: 10px; }
  .header.header-style-02 {
    padding: 0px; }
    .header.header-style-02 .navbar .navbar-brand {
      margin-right: 10px; }
    .header.header-style-02 .news-headlin {
      width: 26%;
      margin-right: 10px; }
    .header.header-style-02 .weather {
      display: none; }
  .header.header-style-03 {
    width: 100%;
    padding: 0 30px;
    background-color: rgba(255, 255, 255, 0.8); }
    .header.header-style-03 .navbar {
      padding: 20px 0px; }
  .header.header-style-03.is-sticky {
    background-color: white; }
    .header.header-style-03.is-sticky .navbar {
      padding: 15px 0px; }
  .header.header-style-04 {
    padding: 0px 0px; }
    .header.header-style-04 .navbar .navbar-nav .nav-item .nav-link {
      padding: 10px; }
  .header.header-style-06 .trending .news-headlin {
    width: 250px; }
  .header.header-style-06 .topbar .topbar-date {
    padding-right: 8px;
    margin-right: 8px;
    font-size: 14px; }
  .header.header-style-06 .topbar .top-menu li {
    margin-right: 10px; }
    .header.header-style-06 .topbar .top-menu li a {
      font-size: 14px; }
  .header.header-style-06.is-sticky .trending .title {
    padding-right: 15px; }
  .header.header-style-06.is-sticky .trending .news-headlin {
    width: 150px;
    margin-right: 20px; } }

@media (max-width: 991px) {
  .header .topbar .topbar-right {
    justify-content: center; }
  .header .navbar-collapse {
    position: absolute;
    top: 100%;
    z-index: 999;
    background: #ffffff;
    width: 100%;
    max-height: 350px;
    overflow: auto;
    left: 0;
    margin-top: -1px;
    margin-left: 0px;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .header .navbar {
    padding: 20px 0px; }
    .header .navbar .navbar-brand img {
      height: auto; }
    .header .navbar .navbar-nav {
      align-items: initial; }
      .header .navbar .navbar-nav .nav-item {
        margin-right: 0; }
        .header .navbar .navbar-nav .nav-item .nav-link {
          color: #5c667d;
          padding: 10px 15px;
          font-size: 16px; }
          .header .navbar .navbar-nav .nav-item .nav-link i {
            position: absolute;
            right: 20px;
            top: 20px; }
        .header .navbar .navbar-nav .nav-item .inner-mega-menu {
          display: none; }
      .header .navbar .navbar-nav .dropdown.mega-menu .megamenu {
        width: 100%;
        margin: 0 auto;
        min-height: auto; }
    .header .navbar .dropdown-menu {
      font-size: 16px;
      border-radius: 0px;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: inherit;
      padding: 0 30px;
      border-radius: 6px; }
      .header .navbar .dropdown-menu .dropdown-item {
        padding: 10px 0px; }
      .header .navbar .dropdown-menu .dropdown-item.active {
        padding-left: 10px;
        background-color: rgba(48, 201, 106, 0.07);
        color: #30c96a; }
    .header .navbar .nav-title {
      margin-top: 20px; }
    .header .navbar .navbar-toggler {
      position: absolute;
      right: 12px;
      box-shadow: none;
      transition: 0.5s ease-in-out;
      background: #30c96a;
      color: #ffffff;
      padding: 15px;
      border-radius: 4px;
      display: flex;
      height: 40px;
      width: 45px;
      justify-content: center;
      align-items: center; }
    .header .navbar .add-listing {
      padding-right: 50px; }
      .header .navbar .add-listing .menu-btn {
        display: none; }
  .header.is-sticky .navbar {
    padding: 10px 0px; }
  .header.header-transparent .navbar .navbar-nav .nav-item .nav-link {
    color: #5c667d; }
  .header.header-transparent .navbar .navbar-nav .nav-item.active .nav-link {
    color: #30c96a; }
  .header.header-style-02 {
    position: inherit;
    background-color: #051830;
    padding: 0px; }
    .header.header-style-02 .navbar .navbar-brand img {
      height: auto; }
    .header.header-style-02 .navbar .navbar-nav .nav-item .nav-link {
      padding: 10px 15px;
      font-size: 16px; }
    .header.header-style-02 .news-headlin {
      width: 48%;
      margin-right: 10px; }
  .header.header-style-03 .navbar .add-listing {
    padding-right: 0px; }
  .header.header-style-06 .trending .news-headlin {
    width: 250px; }
  .header.header-style-06 .navbar {
    padding: 15px 0px; }
    .header.header-style-06 .navbar .navbar-nav .nav-item:first-child .nav-link {
      padding-left: 15px; }
  .header.header-style-06.is-sticky .trending .title {
    padding-right: 30px; }
  .header.header-style-06.is-sticky .trending .news-headlin {
    width: 300px;
    margin-right: 30px; }
  .categories-desktop {
    display: none; } }

@media (max-width: 767px) {
  .header .weather {
    display: none; }
  .header .add-listing .clock {
    display: none; }
  .header.header-style-02 .navbar {
    padding-top: 50px; }
  .header.header-style-02 .news-headlin {
    position: absolute;
    top: -40px;
    width: 90%;
    left: 0;
    right: 0;
    margin: 0 auto; }
  .header.header-style-02.is-sticky .navbar {
    padding-top: 10px; }
  .header.header-style-02.is-sticky .news-headlin {
    display: none; }
  .header.header-style-04 {
    padding: 0px 0px; }
  .header.header-style-06 .navbar {
    padding-top: 15px; }
  .header.header-style-06 .trending {
    display: none; }
  .header.header-style-06.is-sticky .trending {
    display: none; }
  .offcanvas.offcanvas-start {
    padding: 30px; }
    .offcanvas.offcanvas-start .offcanvas-header {
      padding-top: 0; } }

@media (max-width: 575px) {
  .header .navbar {
    padding: 15px 0; }
    .header .navbar .add-listing {
      padding-right: 60px; }
  .header.header-style-06 .trending {
    display: none; }
    .header.header-style-06 .trending .title {
      padding-right: 10px;
      font-size: 14px; }
    .header.header-style-06 .trending .news-headlin {
      width: 60%;
      margin-right: 10px; }
      .header.header-style-06 .trending .news-headlin ul li {
        font-size: 14px; }
  #search input[type="search"] {
    padding-left: 10px;
    padding-right: 10px; }
    #search input[type="search"] .close {
      right: 10px; }
  #search .btn {
    font-size: 14px;
    padding: 14px 15px; } }

/*****************************
  Footer
*****************************/
.footer {
  background-color: #041f42;
  padding-top: 60px; }
  .footer .main-footer {
    padding-bottom: 36px; }
  .footer .footer-about {
    padding-right: 40px; }
    .footer .footer-about .footer-logo {
      margin-bottom: 25px;
      display: block; }
    .footer .footer-about p {
      color: #ffffff;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 35px; }
  .footer .footer-title {
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative; }
    .footer .footer-title:before {
      position: absolute;
      content: "";
      height: 2px;
      width: 60px;
      background-color: #30c96a;
      left: 0;
      bottom: 0; }
  .footer .footer-menu li a {
    margin-bottom: 17px;
    display: block;
    transition: 0.5s;
    position: relative;
    color: #e2e2e2; }
    .footer .footer-menu li a i {
      margin-right: 10px;
      font-size: 12px; }
    .footer .footer-menu li a:hover {
      color: #30c96a;
      padding-left: 5px; }
  .footer .footer-post {
    padding-right: 35px; }
    .footer .footer-post .blog-post {
      display: flex;
      margin-bottom: 20px; }
      .footer .footer-post .blog-post .blog-image {
        width: 110px;
        flex: 0 0 110px;
        margin-right: 24px;
        border-radius: 6px; }
        .footer .footer-post .blog-post .blog-image img {
          border-radius: 6px; }
        .footer .footer-post .blog-post .blog-image:before {
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.3) 100%);
          height: 70%; }
      .footer .footer-post .blog-post .blog-post-details .blog-title a {
        font-size: 18px;
        color: #ffffff;
        font-weight: 600; }
        .footer .footer-post .blog-post .blog-post-details .blog-title a:hover {
          color: #30c96a; }
      .footer .footer-post .blog-post .blog-post-meta .blog-post-time a {
        color: #ffffff; }
        .footer .footer-post .blog-post .blog-post-meta .blog-post-time a i {
          color: #ffffff;
          transition: all 0.3s ease-in-out; }
        .footer .footer-post .blog-post .blog-post-meta .blog-post-time a:hover {
          color: #30c96a; }
          .footer .footer-post .blog-post .blog-post-meta .blog-post-time a:hover i {
            color: #30c96a; }
  .footer .footer-insta {
    display: flex;
    flex-wrap: wrap; }
    .footer .footer-insta li {
      position: relative;
      width: 30%;
      margin-right: 10px;
      margin-bottom: 10px; }
      .footer .footer-insta li a {
        display: block;
        border-radius: 6px; }
        .footer .footer-insta li a img {
          border-radius: 6px; }
      .footer .footer-insta li span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(255, 255, 255, 0.6);
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        transition: all 0.3s ease-in-out;
        opacity: 0;
        cursor: pointer; }
        .footer .footer-insta li span i {
          font-size: 26px;
          color: #041f42; }
      .footer .footer-insta li:hover span {
        opacity: 1; }
  .footer .footer-social .social-icons {
    display: flex; }
    .footer .footer-social .social-icons li {
      margin-right: 30px; }
      .footer .footer-social .social-icons li:last-child {
        margin-right: 0px; }
      .footer .footer-social .social-icons li a {
        font-size: 22px;
        color: #ffffff; }
        .footer .footer-social .social-icons li a:hover {
          color: #30c96a; }
  .footer .footer-bottom {
    background-color: #051830;
    padding: 25px 0px; }
    .footer .footer-bottom .copyright {
      color: #ffffff; }
  .footer .footer-useful-List .footer-menu {
    width: 50%;
    display: inline-block; }

/*footer-style-02*/
.footer.footer-style-02 .footer-logo {
  justify-content: center;
  margin: 0 auto; }

.footer.footer-style-02 .footer-menu ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .footer.footer-style-02 .footer-menu ul li a {
    padding: 12px 18px;
    margin-bottom: 0px; }

.footer.footer-style-02 .social-icon.social-rounded ul {
  padding: 0;
  display: flex;
  justify-content: center; }
  .footer.footer-style-02 .social-icon.social-rounded ul li a {
    margin: 0px 15px; }

.footer.footer-style-02 .footer-bottom {
  background-color: #041f42; }
  .footer.footer-style-02 .footer-bottom .copyright p.text-white a {
    color: #ffffff; }
    .footer.footer-style-02 .footer-bottom .copyright p.text-white a:hover {
      color: #30c96a; }

.footer.footer-style-02 hr {
  opacity: 0.1; }

/*footer-style-03*/
.footer.footer-style-03 {
  background: #051830; }
  .footer.footer-style-03 .blog-post {
    margin-bottom: 30px; }
    .footer.footer-style-03 .blog-post .blog-post-details .blog-title {
      color: #ffffff;
      font-size: 18px; }
    .footer.footer-style-03 .blog-post .blog-post-details .blog-post-meta {
      margin-top: 5px; }
      .footer.footer-style-03 .blog-post .blog-post-details .blog-post-meta a {
        color: #ffffff;
        font-size: 14px; }
    .footer.footer-style-03 .blog-post:last-child {
      margin-bottom: 0px; }
  .footer.footer-style-03 .newsletter-box {
    position: relative; }
    .footer.footer-style-03 .newsletter-box input {
      height: 50px;
      background: transparent;
      border: 1px solid #1e3045;
      font-size: 14px;
      color: #ffffff;
      padding-left: 20px;
      padding-right: 130px; }
      .footer.footer-style-03 .newsletter-box input:focus {
        box-shadow: none; }
    .footer.footer-style-03 .newsletter-box input::placeholder {
      color: #ffffff; }
    .footer.footer-style-03 .newsletter-box .submit-btn {
      position: absolute;
      top: 1px;
      right: 0;
      border-radius: 0px 6px 6px 0px;
      font-size: 14px;
      padding: 14px 30px 13px;
      width: auto;
      color: #ffffff; }
  .footer.footer-style-03 .footer-botoom {
    border-top: 1px solid #1e3655;
    padding-top: 40px;
    align-items: center;
    display: flex;
    flex-wrap: wrap; }
    .footer.footer-style-03 .footer-botoom h6 {
      padding-right: 40px; }
    .footer.footer-style-03 .footer-botoom ul li {
      display: inline-block;
      margin-bottom: 10px;
      margin-top: 10px;
      padding-right: 30px; }
      .footer.footer-style-03 .footer-botoom ul li a {
        margin-bottom: 0px; }
        .footer.footer-style-03 .footer-botoom ul li a:after {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          background-color: #30c96a;
          top: 50%;
          left: -16px;
          transform: translateY(-50%);
          border-radius: 50px;
          opacity: 0;
          visibility: hidden;
          transition: 0.5s; }
        .footer.footer-style-03 .footer-botoom ul li a:hover {
          color: #30c96a;
          background-color: inherit;
          padding-left: 16px; }
          .footer.footer-style-03 .footer-botoom ul li a:hover:after {
            opacity: 1;
            visibility: visible;
            left: 0; }

@media (max-width: 1299px) {
  .footer .footer-insta li {
    width: 29%; } }

@media (max-width: 991px) {
  .footer .footer-about {
    padding-right: 0; }
    .footer .footer-about p {
      margin-bottom: 15px; }
  .footer .footer-post {
    padding-right: 0; } }

@media (max-width: 767px) {
  .footer {
    padding-top: 50px; }
    .footer .main-footer {
      padding-bottom: 16px; } }

@media (max-width: 479px) {
  .footer {
    padding-top: 40px; }
    .footer .main-footer {
      padding-bottom: 6px; }
    .footer .footer-post .blog-post {
      display: block; }
      .footer .footer-post .blog-post .blog-image {
        width: 100%;
        margin-bottom: 15px; } }
